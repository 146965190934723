import React from 'react';
import { primary } from '../../consts/colors';

// Animation code is located in index.css (.loader)
const styles = {
  innerContainer: {
    width: '4rem',
    textAlign: 'center',
    fontSize: '10px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  outerContainer: {
    display: 'flex',
    justifyContent: 'center',
    fontSize: '0.8rem',
    padding: '0.6rem 0',
    textAlign: 'center',
  },
  rectangle: {
    background: primary,
    height: '100%',
    width: '10px',
    display: 'inline-block'
  }
};

export function HorizontalLoader(props) {
  // The following heights will make "Golden Ratio" rectangles when expanded / contracted respectively
  const height = props.height === 'short'? '1.01127rem' : '2.528178rem';
  const background = props.theme === 'white' ? 'white' : primary;
  return (
    <div style={styles.outerContainer}>
      <div className="loader" style={{ ...styles.innerContainer, height}}>
        <div className="rect1" style={{...styles.rectangle, background, opacity: 0.5}}></div>
        <div className="rect2" style={{...styles.rectangle, background, opacity: 0.75}}></div>
        <div className="rect3" style={{...styles.rectangle, background, opacity: 1.0}}></div>
        <div className="rect4" style={{...styles.rectangle, background, opacity: 0.75}}></div>
        <div className="rect5" style={{...styles.rectangle, background, opacity: 0.5}}></div>
      </div>
    </div>
  );
}
