import {
  ADD_FRIEND,
  DISABLE_POINTER_EVENTS,
  ENABLE_POINTER_EVENTS,
  HANDLE_HASH_CHANGE,
  REDUX_UPDATE,
  REDUX_NESTED_UPDATE,
  UPDATE_SHOW_TABS,
  UPDATE_USER_DATA,
  UPDATE_WINDOW_HEIGHT
} from './types';

export function addFriend(newFriend) {
  return {
    type: ADD_FRIEND,
    payload: newFriend
  }
}

export function disablePointerEvents() {
  return {
    type: DISABLE_POINTER_EVENTS
  };
}

export function enablePointerEvents() {
  return {
    type: ENABLE_POINTER_EVENTS
  };
}

export function handleHashChange( hash ) {
  return {
    type: HANDLE_HASH_CHANGE,
    hash
  };
}

export function reduxUpdate({ prop, value }) {
  return {
    type: REDUX_UPDATE,
    payload: { prop, value }
  };
}

export function reduxNestedUpdate({ prop1, prop2, value }) {
  return {
    type: REDUX_NESTED_UPDATE,
    payload: { prop1, prop2, value }
  };
}

export function updateShowTabs(bool) {
  return {
    type: UPDATE_SHOW_TABS,
    payload: bool
  }
}

export function updateUserData(userData) {
  return {
    type: UPDATE_USER_DATA,
    payload: userData
  }
}

export function updateWindowHeight(value) {
  return {
    type: UPDATE_WINDOW_HEIGHT,
    payload: value
  };
}
