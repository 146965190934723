import React from 'react';
import { connect } from 'react-redux';
import * as firebase from 'firebase/app';

import { appBarHeight, tabsHeight, videoBoxShadow } from '../consts/styles';
import { writeShareRef } from '../utility/functions/cloudFirestoreFunctions';
import SendVideoDialog from './SendVideoDialog';
import { reduxUpdate, reduxNestedUpdate } from '../actions/Actions';
import { updateURLFragmentString } from '../utility/functions/misc';
import { TimerStrip } from './common/TimerStrip';
import { addAudioToMediaStream, removeAudioFromMediaStream} from '../utility/functions/cameraFunctions';

var mediaRecorder;
var recordedBlobs;
var superBuffer;

var recordedVideo;
var video;
let touchingRecordButton;

const HIDEBUTTONS = 'HIDEBUTTONS';
const STREAMING = 'STREAMING';
const RECORDING = 'RECORDING';
const FLASHING = 'FLASHING';
const REVIEWING = 'REVIEWING';

const windowWidth = window.innerWidth;
const videoHeight = windowWidth * 1.3;

const reviewIconsSize = '2rem';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'hidden',
  },
  videoContainer: {
    height: videoHeight,
    zIndex: 2,
    boxShadow: videoBoxShadow,
  },
  videoStyle: {
    width: windowWidth,
    height: videoHeight,
  },
  videoOverlayContainer: {
    width: '100vw',
    position: 'absolute',
    top: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  videoOverlayCamera: {
    width: '100%',
    height: videoHeight,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'stretch',
  },
  bottomContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    height: '6rem',
    padding: '12px',
    fontSize: '50px',
  },
};

class Camera extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      status: STREAMING,
      downloadURL: null,
    };
  }

  componentDidMount() {
    const cameraTimerStrip = document.getElementById('cameraTimerStrip');
    cameraTimerStrip.addEventListener('transitionend', () => {
      if (this.state.status === RECORDING) {
        this.stopRecording();
      }
    });

    // Code for Safari 3.1 to 6.0  (Not gonna use this anytime soon):
    // document.getElementById("timerStrip").addEventListener("webkitTransitionEnd", this.handleTransitionEnd);

    const videoOverlayCamera = document.getElementById('videoOverlayCamera');
    videoOverlayCamera.addEventListener(
      'transitionend',
      this.transitionToPlayVideo
    );

    const recordButton = document.getElementById('recordButton');

    recordButton.addEventListener('touchstart', (e) => {
      e.preventDefault();
      touchingRecordButton = true;
      this.startRecording();
    });

    recordButton.addEventListener('touchend', (e) => {
      e.preventDefault();
      touchingRecordButton = false;
      if (this.state.status === RECORDING) {
        this.stopRecording();
      }
    });

    recordButton.addEventListener('touchcancel', (e) => {
      e.preventDefault();
      touchingRecordButton = false
      if (this.state.status === RECORDING) {
        this.stopRecording();
      }
    });

    recordedVideo = document.querySelector('video#recorded');
    video = document.getElementById(this.props.id);
    video.srcObject = window.stream;
    recordedVideo.addEventListener(
      'error',
      function(ev) {
        console.error('MediaRecording.recordedMedia.error()');
        alert(
          'Your browser can not play\n\n' +
            recordedVideo.src +
            '\n\n media clip. event: ' +
            JSON.stringify(ev)
        );
      },
      true
    );
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (
      prevProps.appearanceType === 'send-to' &&
      this.props.appearanceType === 'review'
    ) {
      window.history.back(); // This will trigger the conditional below:
    }
    if (
      prevProps.appearanceType === 'review' &&
      this.props.appearanceType === 'menu'
    ) {
      recordedVideo.pause();
      this.setState({
        status: STREAMING,
        shareRef: null,
        downloadURL: null,
      });
    }
  };

  componentWillUnmount() {
    recordedVideo.pause();
    this.props.reduxNestedUpdate({
      prop1: 'appearance',
      prop2: 'disableSwipe',
      value: false,
    });
  }

  handleDataAvailable = (event) => {
    if (event.data && event.data.size > 0) {
      recordedBlobs.push(event.data);
    }
  };

  handleStop = (event) => {
    console.log('Recorder stopped: ', event);
  };

  startRecording = async () => {
    addAudioToMediaStream()
    .then( async () => {
      await new Promise(resolve => setTimeout(resolve, 50));
      recordedBlobs = [];
      var options = { mimeType: 'video/webm;codecs=vp9' };
      if (!MediaRecorder.isTypeSupported(options.mimeType)) {
        console.log(options.mimeType + 'XXX is not Supported');
        options = { mimeType: 'video/webm;codecs=vp8' };
        if (!MediaRecorder.isTypeSupported(options.mimeType)) {
          console.log(options.mimeType + 'YYY is not Supported');
          options = { mimeType: 'video/webm' };
          if (!MediaRecorder.isTypeSupported(options.mimeType)) {
            console.log(options.mimeType + 'ZZZ is not Supported');
            options = { mimeType: '' };
          }
        }
      }
      if (!touchingRecordButton) {
        const snackbar = {
          open: true,
          message: 'Press and hold to record',
        };
        return this.props.reduxUpdate({
          prop: 'snackbar',
          value: snackbar,
        });
      }
      try {
        mediaRecorder = new MediaRecorder(window.stream, options);
      } catch (e) {
        console.error('Exception while creating MediaRecorder::: ' + e);
        alert(
          'Exception while creating MediaRecorder: ' +
            e +
            '. mimeType: ' +
            options.mimeType
        );
        return;
      }
      this.setState({
        status: RECORDING,
      });
      this.props.reduxNestedUpdate({
        prop1: 'appearance',
        prop2: 'disableSwipe',
        value: true,
      });
      mediaRecorder.onstop = this.handleStop;
      mediaRecorder.ondataavailable = this.handleDataAvailable;
      mediaRecorder.start(10);
    })
  };

  stopRecording = () => {
    this.props.reduxNestedUpdate({
      prop1: 'appearance',
      prop2: 'disableSwipe',
      value: false,
    });
    mediaRecorder.stop();
    if (recordedBlobs.length < 10) {
      this.setState({
        status: STREAMING,
      });
      const snackbar = {
        open: true,
        message: 'Press and hold to record',
      };
      return this.props.reduxUpdate({
        prop: 'snackbar',
        value: snackbar,
      });
    }
    removeAudioFromMediaStream();
    // Setting status to FLASHING kicks off the flash transition on videoOverlayCamera
    // the videoOverlayCamera element is listening for the transition to finish before
    // playing recordedVideo, see transitionToPlayVideo
    this.setState({
      status: FLASHING,
    });

    // recordedVideo.controls = true; // GOOD TO KNOW
    superBuffer = new Blob(recordedBlobs, { type: 'video/webm' });
    recordedVideo.src = window.URL.createObjectURL(superBuffer);

    // TODO BELOW BLOCK DISABLES PAUSE IN VIDEO PLAYER
    // workaround for non-seekable video taken from
    // https://bugs.chromium.org/p/chromium/issues/detail?id=642012#c23
    // recordedVideo.addEventListener('loadedmetadata', function() {
    // if (recordedVideo.duration === Infinity) {
    //   recordedVideo.currentTime = 1e101;
    //   recordedVideo.ontimeupdate = function() {
    //     recordedVideo.currentTime = 0;
    //     recordedVideo.ontimeupdate = function() {
    //       delete recordedVideo.ontimeupdate;
    //       console.log('ABOUT TO PLAY');
    //       // recordedVideo.play();
    //     };
    //   };
    // }
    // });
    recordedVideo.controls = false; // GOOD TO KNOW
  };

  transitionToPlayVideo = () => {
    if (this.state.status === FLASHING) {
      // Set status to REVIEWING so the flash disappears
      this.setState({ status: REVIEWING });
      updateURLFragmentString('review');
      recordedVideo.play();
    }
  };

  onAccept = () => {
    recordedVideo.pause();
    this.setState({ status: HIDEBUTTONS });
    updateURLFragmentString('send-to');

    // Save the question video to firebase storage:
    const date = new Date();
    const month = date.getMonth();
    const year = date.getFullYear();
    const time = date.getTime();
    const folderName = month + '-' + year; // folderName can be used to delete old storage manually
    const questionsStorageRef = firebase
      .storage()
      .ref()
      .child('questions')
      .child(folderName)
      .child(this.props.userId)
      .child(`${time}.webm`);
    var uploadTask = questionsStorageRef.put(superBuffer);

    // Register three observers:
    // 1. 'state_changed' observer, called any time the state changes
    // 2. Error observer, called on failure
    // 3. Completion observer, called on successful completion
    uploadTask.on(
      'state_changed',
      (snapshot) => {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log('Upload is ' + progress + '% done');
        switch (snapshot.state) {
          case firebase.storage.TaskState.PAUSED: // or 'paused'
            console.log('Upload is paused');
            break;
          case firebase.storage.TaskState.RUNNING: // or 'running'
            console.log('Upload is running');
            break;
          default:
            break;
        }
      },
      (error) => {
        // TODO Handle unsuccessful uploads
      },
      () => {
        // Handle successful uploads on complete
        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
          console.log('File available at', downloadURL);
          // After saving video to Storage, set the shareRef in Cloud Firestore:
          this.setState({
            downloadURL, // || 'NOT SET' ???
          });
          writeShareRef(
            this.props.userId,
            this.props.displayName,
            downloadURL
          ).then((shareRef) => {
            this.setState({
              shareRef,
            });
          });
        });
      }
    );
  };

  renderButtons(status) {
    // const {status} = this.state;
    const recordButtonStreamingDisplay = status === STREAMING ? 'flex' : 'none';
    const recordButtonRecordingDisplay = status === RECORDING ? 'flex' : 'none';
    const acceptButtonDisplay = status === REVIEWING ? 'flex' : 'none';
    const cancelButtonDisplay = acceptButtonDisplay;
    return (
      <div
        style={{
          ...styles.videoOverlayContainer,
          height: this.props.windowHeight - appBarHeight - tabsHeight,
        }}
      >
        {' '}
        {/* TODO Refactor with position absolute? */}
        <div
          // In index.css videoOverlay has a transition kicked off by appending flashing:
          className={`videoOverlay ${status === FLASHING ? 'flashing' : ''}`}
          id="videoOverlayCamera"
          style={styles.videoOverlayCamera}
        >
          <div style={styles.bottomContainer}>
            <button
              onClick={() => window.history.back()}
              style={{
                display: cancelButtonDisplay,
                background: '#5d5d5d80',
                borderRadius: '50%',
                color: '#ffbbe4',
                border: '2px solid',
                height: '3rem',
                width: '3rem',
                justifyContent: 'center',
                alignItems: 'center',
                marginRight: '4rem',
                marginTop: '0.5rem'
              }}
            >
              <i
                className="material-icons"
                style={{ fontSize: reviewIconsSize }}
              >
                clear
              </i>
            </button>
            <button id="recordButton">
              <div style={{ display: recordButtonStreamingDisplay }}>
                <div
                  style={{
                    height: '5rem',
                    width: '5rem',
                    border: `0.5rem solid #ff92b7`,
                    borderRadius: '50%',
                  }}
                />
              </div>
              <div
                style={{ display: recordButtonRecordingDisplay }}
                className="spinner"
              />
            </button>
            <button
              onClick={this.onAccept.bind(this)}
              style={{
                display: acceptButtonDisplay,
                color: '#9affe7',
                background: '#5d5d5d80',
                borderRadius: '50%',
                border: '2px solid',
                width: '3rem',
                height: '3rem',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: '0.5rem'
              }}
            >
              <i
                className="material-icons"
                style={{ fontSize: reviewIconsSize, width: reviewIconsSize }}
              >
                done
              </i>
            </button>
          </div>
        </div>
      </div>
    );
  }

  render() {
    // Here we show / hide the question and answer video elements based on state.status:
    let streamVideoDisplay;
    let recordedVideoDisplay;
    const { status } = this.state;
    if (status === REVIEWING || status === HIDEBUTTONS || status === FLASHING) {
      streamVideoDisplay = 'none';
      recordedVideoDisplay = 'inline';
    } else if (status === STREAMING || status === RECORDING) {
      streamVideoDisplay = 'inline';
      recordedVideoDisplay = 'none';
    } else {
      // TODO Handle this, however it shouldn't happen, maybe show loader or something
    }
    return (
      <div style={styles.container}>
        <SendVideoDialog
          downloadURL={this.state.downloadURL}
          shareRef={this.state.shareRef}
          openSendVideo={this.props.appearanceType === 'send-to'}
        />
        <div style={styles.videoContainer}>
          <TimerStrip
            status={this.state.status}
            timerStripId="cameraTimerStrip"
          />
          <video
            disableremoteplayback="true"
            id={this.props.id}
            style={{ ...styles.videoStyle, display: streamVideoDisplay }}
            autoPlay
            muted
            type="video/webm"
          />
          <video
            disableremoteplayback="true"
            id="recorded"
            style={{ ...styles.videoStyle, display: recordedVideoDisplay }}
            playsInline
            autoPlay
            loop
            muted={false}
            type="video/webm"
          />
          {this.renderButtons(status)}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userId: state.global.user.uid,
    displayName: state.global.user.displayName,
    appearanceType: state.global.appearance.appearanceType,
    windowHeight: state.global.windowHeight,
  };
};

export default connect(mapStateToProps, { reduxUpdate, reduxNestedUpdate })(
  Camera
);
