import React from 'react';
import { connect } from 'react-redux';
import { reduxNestedUpdate } from '../actions/Actions';

import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import { charcoal, lightCharcoal } from '../consts/colors';
import { debounce } from '../utility/functions/misc';
import { ProfilePhoto } from './common/ProfilePhoto';
import { DummyAppBar } from './common/DummyAppBar';

// Required for side-effects
require('firebase/firestore');
let debouncedSearch;
const usernameMaxChars = 15;
const displayNameMaxChars = 25;

const styles = {
  outterContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  innerContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '0 1.5rem',
    flex: 1,
  },
  topContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: '2rem 0',
    color: 'darkslategray',
    WebkitFontSmoothing: 'antialiased',
  },
  previewContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '0.75rem',
  },
  userDetail: {
    margin: '3px 0 0 8px',
  },
  text: {
    textAlign: 'center',
    fontSize: '0.75rem',
  },
  inputAdornment: {
    margin: '0 -5px 3px 0',
  },
  button: {
    margin: '10px 15px 0',
    width: 'fit-content',
    alignSelf: 'center'
  },
  signOutButton: {
    color: charcoal,
    border: `1px solid ${lightCharcoal}`,
    borderRadius: '5px',
    padding: '5px 10px',
    margin: '2rem 0 1.5rem',
    width: 'fit-content',
    fontSize: '0.75rem',
  },
  fieldContainer: {
    display: 'flex',
  },
  icon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '2rem',
  },
};

let db;

class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: {
        value: '',
        available: null,
        error: null,
        helperText: '(4 character min)',
      },
      displayName: '', // Here we can check redux!
    };
    this.usernameChange = this.usernameChange.bind(this);
  }

  componentDidMount() {
    db = window.firestore;
    // Define the debounced function
    debouncedSearch = debounce(this.checkAvailability.bind(this), 1000);
  }

  usernameChange = (event) => {
    // Username is always lowercase
    const lowerCaseUserName = event.target.value.toLowerCase();

    if (lowerCaseUserName.length > usernameMaxChars) {
      this.setState({
        username: {
          ...this.state.username,
          helperText: '15 Character Limit',
        },
      });
      return;
    }

    const regex = /^\w+$/; // Check for only letters, numbers, and underscores
    if (!regex.test(lowerCaseUserName) && lowerCaseUserName) {
      this.setState({
        username: {
          ...this.state.username,
          helperText: 'Letters, numbers, and _ only',
        },
      });
      return;
    }

    this.setState({
      username: {
        ...this.state.username,
        error: false,
        available: false,
        helperText: '(4 character min)',
        showSpinner: true,
        value: lowerCaseUserName,
      },
    });
    debouncedSearch(this.props.searchInputValue);
  };

  displayNameChange = (event) => {
    // const displayNameLength = this.state.displayName.length;
    let { value } = event.target;

    // Odd defect happening when spacebar hits, this is a hacky work around:
    // if (value.length < displayNameLength - 1) return;
    // else if (value.length > displayNameLength + 1) {
    //   value = value.substring(0, displayNameLength);
    //   if (value === '') {
    //     return;
    //   }
    //   value = value + ' ';
    // }

    const regex = /^[a-zA-Z0-9. ]*$/; // Check for only letters, numbers, spaces, and periods
    if (!regex.test(value)) {
      this.setState({
        displayNameHelperText: 'no special characters',
      });
      return;
    }

    // Regex removes multiple spaces between words, substring limits Display Name to Max Chars:
    // const displayName = value.replace(/\s\s+/g, ' ').substring(0, displayNameMaxChars);
    const displayName = value
      .replace(/\s\s+/g, ' ')
      .substring(0, displayNameMaxChars);
    this.setState({
      displayName,
      displayNameHelperText: null,
    });
  };

  checkAvailability() {
    const username = this.state.username.value;
    if (username.length < 4) {
      this.makeUnavailable('4 character min');
      return;
    }
    console.log('Query for: ', username);
    db.collection('users')
      .where('username', '==', username)
      .get()
      .then((querySnapshot) => {
        console.log('checkavailability querysnapshot: ', querySnapshot);
        // NEED TO HANDLE CASE WHERE THERE IS NO RESPONSE FROM FIRESTORE, shouldn't return available
        if (querySnapshot.empty) {
          this.makeAvailable();
        } else {
          this.makeUnavailable('Taken!');
        }
      })
      .catch((error) => {
        console.log('Error getting documents: ', error);
        this.setState({
          showSpinner: false,
        });
      });
  }

  makeAvailable() {
    this.setState({
      username: {
        ...this.state.username,
        available: true,
        error: false,
        helperText: 'Available',
        showSpinner: false,
      },
    });
  }

  makeUnavailable(helperText) {
    this.setState({
      username: {
        ...this.state.username,
        error: true,
        available: false,
        showSpinner: false,
        helperText: helperText,
      },
    });
  }

  saveAndContinue = () => {
    // fullscreenPortraitMode();

    if (this.state.username.available === true) {
      const username = this.state.username.value;
      // For user queries, create an array of the first 4, 5, and 6 characters of the username:
      const usernameQueryArray = [
        username.substring(0, 4),
        username.substring(0, 5),
        username.substring(0, 6),
      ];
      const displayName = this.state.displayName.trim();
      // displayNameLowercase and displayNameArray to query for user:
      const displayNameLowercase = displayName.toLowerCase();
      const displayNameArray = displayNameLowercase.split(' ');

      db.collection('users')
        .doc(this.props.uid)
        .update({
          username,
          usernameQueryArray,
          displayName,
          displayNameLowercase,
          displayNameArray,
        })
        .then(() => {
          console.log('Document Sucessfully written');
          this.props.reduxNestedUpdate({
            prop1: 'user',
            prop2: 'username',
            value: this.state.username.value,
          });
          this.props.reduxNestedUpdate({
            prop1: 'user',
            prop2: 'displayName',
            value: displayName,
          });
        })
        .catch(function(error) {
          console.error('Error!! ', error);
        });
    }
  };

  renderUsernameFieldIcon() {
    let icon;
    if (this.state.username.showSpinner) {
      icon = <div className="lds-dual-ring" />;
    } else if (this.state.username.error) {
      icon = (
        <i className="material-icons" style={{ color: 'red' }}>
          error_outline
        </i>
      );
    } else if (this.state.username.available) {
      icon = (
        <i className="material-icons" style={{ color: '#26b1a9' }}>
          check
        </i>
      );
    } else {
      icon = null;
    }
    return <div style={styles.icon}>{icon}</div>;
  }

  renderDisplayNameFieldIcon() {
    let icon;
    if (this.state.displayName) {
      icon = (
        <i className="material-icons" style={{ color: '#26b1a9' }}>
          check
        </i>
      );
    } else {
      icon = null;
    }
    return <div style={styles.icon}>{icon}</div>;
  }

  render() {
    let saveButtonHelperText = '';
    if (!this.state.username.available && !this.state.displayName) {
      saveButtonHelperText = '(add a user name and display name)';
    } else if (!this.state.username.available) {
      saveButtonHelperText = '(add a user name)';
    } else if (!this.state.displayName) {
      saveButtonHelperText = '(add a display name)';
    }
    return (
      <div style={styles.outterContainer}>
        <DummyAppBar />
        <div style={styles.innerContainer}>
          <div style={styles.topContainer}>
            <span style={{marginBottom: '2rem', fontSize: '18px'}}>Profile Setup</span>
            <div style={styles.previewContainer}>
              <ProfilePhoto photoURL={this.props.photoURL || null} />
              <div style={{ flexDirection: 'column' }}>
                <p style={styles.userDetail}>
                  {this.state.displayName || ''}
                </p>
                <p style={{...styles.userDetail, fontWeight: 300}}>
                  {this.state.username.value || ''}
                </p>
              </div>
            </div>
            <div style={styles.fieldContainer}>
              <FormControl
                error={this.state.username.error}
                aria-describedby="username-helper-text"
                style={{ flex: 1, marginTop: '1rem' }}
              >
                <InputLabel htmlFor="username-field">Username *</InputLabel>
                <Input
                  autoComplete="off"
                  type="text"
                  id="username-field"
                  onChange={this.usernameChange}
                  value={this.state.username.value}
                />
                <FormHelperText
                  error={this.state.username.error}
                  style={{ textAlign: 'center' }}
                  id="username-helper-text"
                >
                  {this.state.username.helperText}
                </FormHelperText>
              </FormControl>
              {this.renderUsernameFieldIcon()}
            </div>
            <div style={styles.fieldContainer}>
              <FormControl
                error={this.state.displayNameError} // Currently, there will never be helper text for displayName field
                aria-describedby="displayName-helper-text"
                style={{ flex: 1, marginTop: '1rem' }}
              >
                <InputLabel htmlFor="display-name-field">
                  Display Name *
                </InputLabel>
                <Input
                  autoComplete="off"
                  type="text"
                  id="display-name-field"
                  onChange={this.displayNameChange}
                  value={this.state.displayName}
                />
                <FormHelperText
                  error={false}
                  style={{ textAlign: 'center' }}
                  color="primary"
                  id="displayName-helper-text"
                >
                  {this.state.displayNameHelperText}
                </FormHelperText>
              </FormControl>
              {this.renderDisplayNameFieldIcon()}
            </div>
            <Button
              variant="raised"
              color="primary"
              onClick={() => this.saveAndContinue()}
              style={styles.button}
              disabled={this.state.username.error || (this.state.displayName.length < 2)}
            >
              {'Save & Continue'}
            </Button>
            <p style={styles.text}>{saveButtonHelperText}</p>
          </div>
          <button
            onClick={() => this.props.signOut()}
            style={styles.signOutButton}
          >
            Sign Out
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return state.global.user;
};

export default connect(mapStateToProps, { reduxNestedUpdate })(Profile);
