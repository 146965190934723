import React from 'react';
import { lightGray, beige } from './../../consts/colors';

const profilePhotoWidth = '3.7rem';

const styles = {
  outerContainer: {
    padding: '0 0.5rem',
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${lightGray}`,
    padding: '0.7rem 0',
  },
  namesContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    margin: 'auto o',
    padding: '0 5px',
  },
  profilePhoto: {
    height: profilePhotoWidth,
    borderRadius: '50%',
    boxShadow: '-3px 3px 5px rgba(0, 0, 0, .3)',
    border: 'white 1px solid',
  },
  profileIconContainer: {
    width: profilePhotoWidth,
    height: profilePhotoWidth,
    display: 'flex',
    alignContent: 'center',
    background: beige,
    borderRadius: '50%',
    boxShadow: '-3px 3px 5px rgba(0, 0, 0, .3)',
    border: 'white 1px solid',
  },
  profileIcon: {
    color: 'white',
    fontSize: '3rem',
    margin: 'auto',
  },
  spanStyle: {
    fontWeight: 400,
    paddingTop: '5px',
  },
  button: {
    display: 'flex',
    flexDirection: 'row',
    height: '2rem',
    boxShadow: '-3px 3px 5px rgba(0, 0, 0, .3)',
    border: 'white 1px solid',
    borderRadius: '26px',
    padding: '4px 12px 4px 15px',
  },
  buttonIcon: {
    fontSize: '1.6rem',
    color: 'white',
  },
  buttonLabel: {
    color: 'white',
    margin: 'auto 0',
    fontSize: '0.9rem',
  },
  loadingButton: {
    background: beige,
  },
  sendButton: {
    background: '#3dabf1',
  },
  sentButton: {
    background: '#3fe0ba',
  },
};

class UserListItem extends React.PureComponent {
  render() {
    return (
      <div style={styles.outerContainer}>
        <div style={styles.container}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            {(this.props.photoURL && (
              <div>
                <img
                  src={this.props.photoURL}
                  alt="profile"
                  style={styles.profilePhoto}
                />
              </div>
            )) || (
              <div style={styles.profileIconContainer}>
                <i className="material-icons" style={styles.profileIcon}>
                  person
                </i>
              </div>
            )}
            <div style={styles.namesContainer}>
              <span style={styles.spanStyle}>{this.props.displayName}</span>
              <span style={styles.spanStyle}>{this.props.username}</span>
            </div>
          </div>
          {this.props.status === 'loading' && (
            <button style={{ ...styles.button, ...styles.loadingButton }}>
              <span style={styles.buttonLabel}>LOADING</span>
            </button>
          )}
          {this.props.status === 'send' && (
            <button
              style={{ ...styles.button, ...styles.sendButton }}
              onClick={() =>
                this.props.handleSendButtonClick(null, this.props.uid, null)
              }
            >
              <i className="material-icons" style={styles.buttonIcon}>
                send
              </i>
              <span style={styles.buttonLabel}>&nbsp;SEND</span>
            </button>
          )}
          {this.props.status === 'sending' && (
            <button style={{ ...styles.button, ...styles.sendButton }}>
              <span style={styles.buttonLabel}>&nbsp;SENDING</span>
            </button>
          )}
          {this.props.status === 'sent' && (
            <button style={{ ...styles.button, ...styles.sentButton }}>
              <i className="material-icons" style={styles.buttonIcon}>
                check
              </i>
              <span style={styles.buttonLabel}>&nbsp;SENT</span>
            </button>
          )}
        </div>
      </div>
    );
  }
}

export default UserListItem;
