const createConvoUid = (userUid, friendUid)=> {
  let convoArray;
  // Here we sort the array so we each user shares the same key to the convo
  // (Same key will generate regardless of which user is the current client)
  convoArray = [friendUid, userUid];
  convoArray.sort();
  return `${convoArray[0]}${convoArray[1]}`;
}

const debounce = (func, wait) => {
  let timeout;
  // Calling debounce returns a new anonymous function
  return function() {
    // reference the context and args for the setTimeout function
    let context = this,
      args = arguments;
    clearTimeout(timeout);
    // Set the new timeout
    timeout = setTimeout(function() {
      timeout = null;
      func(context, args);
    }, wait);
  }
}

const friendsFilter = (friendsArray, searchString) => {
  const lowerCaseValue = searchString.toLowerCase();

  const filteredFriendsArray = friendsArray.filter(
    friend => {
      return (
        (friend.username && friend.username.includes(lowerCaseValue)) ||
        (friend.displayName &&
          friend.displayName.toLowerCase().includes(lowerCaseValue))
      );
    }
  );
  return filteredFriendsArray
}

const friendsObjectToSortedArray = friendsObject => {

  let friendsArray = [];

  for (let friend in friendsObject) {
    let friendObject = friendsObject[friend];
    friendsArray.push(friendObject);
  }

  // Sort the array in alphabetical order by username
  friendsArray.sort(function(a, b) {
    var friendA = a.username.toLowerCase();
    var friendB = b.username.toLowerCase();
    if (friendA < friendB) {
      return -1;
    }
    if (friendA > friendB) {
      return 1;
    }
    return 0;
  });
  return friendsArray
};

const fullscreenPortraitMode = () => {
  var doc = window.document;
  var docEl = doc.documentElement;

  var requestFullScreen =
    docEl.requestFullscreen ||
    docEl.mozRequestFullScreen ||
    docEl.webkitRequestFullScreen ||
    docEl.msRequestFullscreen;

  requestFullScreen.call(docEl);
  window.screen.orientation.lock("portrait-primary")
  .then(() => {})
  .catch((e) => console.log('window.screen.orientation.lock("portrait-primary") error: ', e));
}

const updateURLFragmentString = (string, updateIndex) => {
  const currentHash = window.location.hash;
  const currentIndex = currentHash.substring(1,2);
  const index = typeof updateIndex === 'number' ? updateIndex : currentIndex;
  const newHash = index + string;
  window.location.hash = newHash;
}

const updateURLFragmentIndex = (newIndex) => {
  const currentHash = window.location.hash;
  const currentIndex = currentHash.substring(1,2);
  const newHash = currentHash.replace(currentIndex, newIndex.toString())
  window.location.hash = newHash
}

export { createConvoUid, debounce, friendsFilter, friendsObjectToSortedArray, fullscreenPortraitMode, updateURLFragmentString, updateURLFragmentIndex };
