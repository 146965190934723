import {
  ADD_FRIEND,
  DISABLE_POINTER_EVENTS,
  ENABLE_POINTER_EVENTS,
  HANDLE_HASH_CHANGE,
  REDUX_UPDATE,
  REDUX_NESTED_UPDATE,
  UPDATE_SHOW_TABS,
  UPDATE_USER_DATA,
  UPDATE_WINDOW_HEIGHT
} from '../actions/types';

import { appName1, appName2WithDomainExt } from '../consts/phrases';
import { friendsObjectToSortedArray } from '../utility/functions/misc';

const INITIAL_STATE = {
  appearance: {
    appBarText1: appName1,
    appBarText2: appName2WithDomainExt,
    appearanceType: 'menu',
    showTabs: true,
  },
  authStatus: 'searching',
  pointerEvents: 'auto',
  searchInputValue: '',
  snackbar: {
    open: false,
    message: ''
  },
  user: {},
  videoLoop: {
    srcUrls: [],
    questionerDisplayName: null,
    answererDisplayName: null,
  },
  windowHeight: window.outerHeight
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ADD_FRIEND:
      const newFriend = action.payload;
      const updatedFriendsObject =  {
        ...state.friendsObject,
        [newFriend.uid]: newFriend,
      };
      const updatedFriendsArray = friendsObjectToSortedArray(updatedFriendsObject);

      return {
        ...state,
        friendsObject: {
          ...state.friendsObject,
          [newFriend.uid]: newFriend,
        },
        friendsArray: updatedFriendsArray
      };
    case DISABLE_POINTER_EVENTS:
      return {
        ...state,
        pointerEvents: 'none'
      };
    case ENABLE_POINTER_EVENTS:
      return {
        ...state,
        pointerEvents: 'auto'
      };
    case HANDLE_HASH_CHANGE:
      const index = parseInt(action.hash.substring(1,2), 10); // Pull the index number out of the hash (2nd char in the string)
      const appearanceType = action.hash.slice(2) // Pull the string off the hash (everything after 2nd char)
      let appBarText1 = state.appearance.appBarText1;
      let appBarText2 = state.appearance.appBarText2;
      let showTabs = null;
      switch(appearanceType) {
        case 'menu':
          appBarText1 = appName1;
          appBarText2 = appName2WithDomainExt;
          showTabs = true;
          break;
        case 'send-to':
          appBarText1 = 'Send To:';
          appBarText2 = '';
          showTabs = false
          break;
        case 'convo':
          showTabs = false
          break;
        case 'convo-oq':
          showTabs = false
          break;
        case 'path-oq':
          showTabs = false;
          break;
        default:
          showTabs = true;
          break;
      }
      return {
        ...state,
        appearance: {
          ...state.appearance,
          swipeIndex: index,
          appearanceType,
          appBarText1,
          appBarText2,
          showTabs: showTabs
        }
      };
    case REDUX_UPDATE:
      return {
        ...state,
        [action.payload.prop]: action.payload.value
      };
    case REDUX_NESTED_UPDATE:
      const { prop1 } = action.payload;
      return {
        ...state,
        [prop1]: {
          ...state[prop1],
          [action.payload.prop2]: action.payload.value
        }
      };
    case UPDATE_SHOW_TABS:
      return {
        ...state,
        appearance: {
          ...state.appearance,
          showTabs: action.payload
        }
      };
    case UPDATE_USER_DATA:
      return {
        ...state,
        user: action.payload
      };
    case UPDATE_WINDOW_HEIGHT:
      return {
        ...state,
        windowHeight: action.payload
      };
    default:
      return state;
  }
};
