import React from 'react';
import { connect } from 'react-redux';
import * as firebase from 'firebase/app';

import {
  enablePointerEvents,
  reduxNestedUpdate,
  reduxUpdate,
} from '../actions';
import {
  updateUser,
  addFriendToDB,
  updateLastContact,
} from '../utility/functions/cloudFirestoreFunctions';
import PreOpenQuestionModalContent from './PreOpenQuestionModalContent';
import { SlideModal } from './common/SlideModal';
import { primaryLight } from '../consts/colors';
import { videoBoxShadow } from '../consts/styles';
import { TimerStrip } from './common/TimerStrip';
import VideoLoop from './common/VideoLoop';
import { createConvoUid } from '../utility/functions/misc';
import { addAudioToMediaStream, removeAudioFromMediaStream} from '../utility/functions/cameraFunctions';


var mediaRecorder;
var recordedBlobs;
var superBuffer;

let questionVideo;
let answerVideo;

const STREAMING = 'STREAMING';
const PLAYING = 'PLAYING';
const RECORDING = 'RECORDING';
const FLASHING = 'FLASHING';
const VIDEOLOOPING = 'VIDEOLOOPING';
const windowWidth = window.innerWidth;
const videoHeight = windowWidth * 1.3;

const styles = {
  authorHeader: {
    width: 'fit-content',
    background: 'rgba(0,0,0,0.1)',
    WebkitFontSmoothing: 'antialiased',
    padding: '0.2rem',
  },
  authorHeaderSpan: {
    color: 'white',
  },
  buttonsContainer: {
    position: 'absolute',
    top: 0,
    width: windowWidth,
    height: videoHeight,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: windowWidth,
  },
  openQuestionContainer: {
    background: 'white',
    position: 'absolute',
    zIndex: 8000,
  },
  recIndicator: {
    color: primaryLight,
    textAlign: 'right',
    fontSize: '1rem',
    margin: '0.3rem',
  },
  recordingButtonsContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  stopButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '8rem',
  },
  stopButton: {
    color: primaryLight,
    lineHeight: 0,
  },
  videoContainer: {
    position: 'relative',
    width: windowWidth,
    height: videoHeight,
    boxShadow: videoBoxShadow,
  },
  questionVideoStyle: {
    width: windowWidth,
    height: videoHeight,
  },
  answerVideoStyle: {
    width: windowWidth,
    height: videoHeight,
  },
};
let convoUid;
let convoRef;

class OpenQuestion extends React.Component {
  constructor(props) {
    super(props);
    let initialStatus;
    if (this.props.dontShowAgain && !this.props.pathQuestion) {
      initialStatus = PLAYING;
      this.props.reduxNestedUpdate({
        prop1: 'appearance',
        prop2: 'hideAppBar',
        value: true,
      });
    } else {
      initialStatus = STREAMING;
    }
    this.state = {
      status: initialStatus,
      initialDontShowAgain: props.dontShowAgain || false,
      showModal: true,
    };
  }

  componentDidMount = () => {
    convoUid = createConvoUid(
      this.props.uid || this.props.anonymousUid,
      this.props.authorUid
    );
    convoRef = window.firestore.collection('convos').doc(convoUid);
    convoRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          console.log('Doc exists, document data:', doc.data());
        } else if (this.props.uid) {
          this.initiateFriendship();
        }
      })
      .catch(function(error) {
        console.log('Error getting document:', error);
      });

    const openQuestionTimerStrip = document.getElementById(
      'openQuestionTimerStrip'
    );
    openQuestionTimerStrip.addEventListener('transitionend', () => {
      if (this.state.status === RECORDING) {
        this.stopRecording();
      }
    });

    const videoOverlayOpenQuestion = document.getElementById(
      'videoOverlayOpenQuestion'
    );
    videoOverlayOpenQuestion.addEventListener(
      'transitionend',
      this.transitionToRecord
    );

    questionVideo = document.getElementById('questionVideo');
    questionVideo.src = this.props.questionSrc;
    answerVideo = document.getElementById('answerVideo');
    answerVideo.srcObject = window.stream;
    answerVideo.addEventListener(
      'error',
      function(ev) {
        console.error('MediaRecording.recordedMedia.error()');
        alert(
          'Your browser can not play\n\n' +
            answerVideo.src +
            '\n\n media clip. event: ' +
            JSON.stringify(ev)
        );
      },
      true
    );
    questionVideo.addEventListener(
      'ended',
      this.startFlashTransition.bind(this),
      false
    );
    // Only show the video if the user previously opted out of the preQuestionModal
    if (this.props.dontShowAgain && !this.props.pathQuestion) {
      questionVideo.play();
    } else {
      answerVideo.play();
    }
  };

  componentWillUnmount() {
    questionVideo.pause();
    answerVideo.pause();
  }

  initiateFriendship = () => {
    window.firestore
      .collection('users')
      .where('uid', '==', this.props.authorUid) // Fetch author's user data:
      .get()
      .then((querySnapshot) => {
        if (querySnapshot.empty) {
          console.log('Author not found as a user.');
        } else {
          console.log('querysnapshot: ', querySnapshot);
          querySnapshot.forEach((doc) => {
            const response = doc.data();

            // if (this.props.uid) { // Client is logged in:
            const { displayName, photoURL, username } = response;
            const newFriend = {
              displayName,
              photoURL,
              uid: this.props.authorUid,
              username,
            };
            let thisUser;
            thisUser = {
              displayName: this.props.displayName,
              photoURL: this.props.photoURL,
              uid: this.props.uid,
              username: this.props.username,
            };
            addFriendToDB(thisUser, newFriend);
            // } else if ( this.props.anonymousUid ) { // Conditional if user is anonymous:
            // anonymousUserConnect(this.props.anonymousUid, this.props.authorUid)
            // } else { // TODO handle this
            //   console.log('An error occurred connecting users')
            //   return
            // }
          });
        }
      })
      .catch(function(error) {
        console.log('Error searching for author as a user: ', error);
      });
  };

  handleDataAvailable = (event) => {
    if (event.data && event.data.size > 0) {
      recordedBlobs.push(event.data);
    }
  };

  handleStop = (event) => {
    console.log('Recorder stopped: ', event);
  };

  startFlashTransition = () => {
    // This kicks off the css transition, there is a listener triggered when the transition ends, which starts the recording
    this.setState({ status: FLASHING });
  };

  startRecording = (event) => {
    addAudioToMediaStream()
    .then( async () => {
      await new Promise(resolve => setTimeout(resolve, 50));
      recordedBlobs = [];
      var options = { mimeType: 'video/webm;codecs=vp9' };
      if (!MediaRecorder.isTypeSupported(options.mimeType)) {
        console.log(options.mimeType + 'XXX is not Supported');
        options = { mimeType: 'video/webm;codecs=vp8' };
        if (!MediaRecorder.isTypeSupported(options.mimeType)) {
          console.log(options.mimeType + 'YYY is not Supported');
          options = { mimeType: 'video/webm' };
          if (!MediaRecorder.isTypeSupported(options.mimeType)) {
            console.log(options.mimeType + 'ZZZ is not Supported');
            options = { mimeType: '' };
          }
        }
      }
      try {
        mediaRecorder = new MediaRecorder(window.stream, options);
      } catch (e) {
        console.error('Exception while creating MediaRecorder::: ' + e);
        alert(
          'Exception while creating MediaRecorder: ' +
            e +
            '. mimeType: ' +
            options.mimeType
        );
        return;
      }
      console.log(
        'Created MediaRecorder',
        mediaRecorder,
        'with options',
        options
      );
      this.setState({
        status: RECORDING,
      });
      mediaRecorder.onstop = this.handleStop;
      mediaRecorder.ondataavailable = this.handleDataAvailable;
      mediaRecorder.start(10); // collect 10ms of data
      console.log('MediaRecorder started', mediaRecorder);
    })
  };

  stopRecording = () => {
    if (this.state.status === RECORDING) {
      // (Should always be a true conditional)
      mediaRecorder.stop();
      superBuffer = new Blob(recordedBlobs, { type: 'video/webm' });
      answerVideo.src = window.URL.createObjectURL(superBuffer);
      answerVideo.controls = false; // Good to know
      removeAudioFromMediaStream();

      // Save the answer video to firebase storage:
      const date = new Date();
      const month = date.getMonth();
      const year = date.getFullYear();
      const time = date.getTime();
      const folderName = month + '-' + year; // folderName can be used to delete old storage manually

      const answerStorageRef = firebase
        .storage()
        .ref()
        .child('answers')
        .child(folderName)
        .child(this.props.uid || this.props.anonymousUid)
        .child(`${time}.webm`);
      var uploadTask = answerStorageRef.put(superBuffer);
      // Register three observers:
      // 1. 'state_changed' observer, called any time the state changes
      // 2. Error observer, called on failure
      // 3. Completion observer, called on successful completion
      uploadTask.on(
        'state_changed',
        (snapshot) => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          var progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log('Upload is ' + progress + '% done');
          switch (snapshot.state) {
            case firebase.storage.TaskState.PAUSED: // or 'paused'
              console.log('Upload is paused');
              break;
            case firebase.storage.TaskState.RUNNING: // or 'running'
              console.log('Upload is running');
              break;
            default:
              break;
          }
        },
        (error) => {
          // TODOO Handle unsuccessful uploads
        },
        () => {
          // Handle successful uploads on complete
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            console.log('File available at', downloadURL);

            if (this.props.pathQuestion) {
              // Update shareRef with this user's id so they can't answer it again:
              this.props.fbShareRef.update({
                usersThatAnswered: firebase.firestore.FieldValue.arrayUnion(
                  this.props.uid || this.props.anonymousUid
                ),
              });
            }

            const date = new Date();
            if (this.props.uid) {
              const type = 'complete';
              const message = {
                questionerUid: this.props.authorUid,
                answererUid: this.props.uid,
                authorDisplayName: this.props.displayName,
                type,
                answerSrc: downloadURL,
                questionSrc: this.props.questionSrc,
                date,
              };

              // After saving video to Storage, add to conversations:
              if (this.props.pathQuestion) {
                // Path questions: ( use .add() )
                convoRef
                  .collection('messages')
                  .add(message)
                  .then((docRef) => {
                    console.log('Document written with ID: ', docRef.id);

                    // Update the last contact date for conversation
                    updateLastContact(
                      convoUid,
                      new Date(),
                      type,
                      this.props.uid,
                      this.props.notificationsToken,
                      this.props.authorUid
                    );
                  })
                  .catch(function(error) {
                    console.error('Error adding document: ', error);
                  });
              } else {
                // Question was sent in the app: ( use .update() )
                // if answering within the app (Not a path question), update the question to a complete pair in convos in Firestore (Only if user is logged in, not anonymous):
                // The update will make the question disappear and a complete video loop will appear as the newest message:
                convoRef
                  .collection('messages')
                  .doc(this.props.documentId)
                  .update(message)
                  .then((docRef) => {
                    console.log('Document updated');
                    // TODO Visually confirm message was sent
                    // Update the last contact date for conversation:
                    updateLastContact(
                      convoUid,
                      new Date(),
                      type,
                      this.props.uid,
                      this.props.notificationsToken,
                      this.props.authorUid
                    );
                  })
                  .catch(function(error) {
                    console.error('Error adding document: ', error);
                  });
              }
            } else {
              // Here we add an anonymous response to convos in the db
              const anonymousAnswer = {
                convoUsers: [this.props.anonymousUid, this.props.authorUid],
                // questionerUid: this.props.authorUid, // NEED?
                // answererUid: this.props.anonymousUid, // NEED?
                type: 'anonymousComplete',
                answerSrc: downloadURL,
                questionSrc: this.props.questionSrc,
                lastContact: date,
                // date // NEED?
              };

              window.firestore
                .collection('convos')
                .add(anonymousAnswer)
                .then(function(docRef) {
                  console.log('Anonymous answer written with ID: ', docRef.id);
                })
                .catch(function(error) {
                  console.error('Error adding anonymous answer: ', error);
                });
            }
          });
        }
      );

      this.setState({
        status: VIDEOLOOPING,
      });
      // this.props.enablePointerEvents();
      this.props.reduxNestedUpdate({
        prop1: 'appearance',
        prop2: 'hideAppBar',
        value: false,
      });

      // TODO BELOW BLOCK DISABLES PAUSE IN VIDEO PLAYER
      // workaround for non-seekable video taken from
      // https://bugs.chromium.org/p/chromium/issues/detail?id=642012#c23
      // recordedVideo.addEventListener('loadedmetadata', function() {
      // if (recordedVideo.duration === Infinity) {
      //   recordedVideo.currentTime = 1e101;
      //   recordedVideo.ontimeupdate = function() {
      //     recordedVideo.currentTime = 0;
      //     recordedVideo.ontimeupdate = function() {
      //       delete recordedVideo.ontimeupdate;
      //       console.log('ABOUT TO PLAY');
      //       // recordedVideo.play();
      //     };
      //   };
      // }
      // });
    }
  };

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleCloseModal() {
    // If user changed their dontShowAgain preference, and are not logged in anonymously
    if (
      this.state.initialDontShowAgain !== this.props.dontShowAgain &&
      this.props.authStatus !== 'anonymousUser'
    ) {
      updateUser(this.props.uid, 'dontShowAgain', this.props.dontShowAgain);
    }
    this.props.reduxNestedUpdate({
      prop1: 'appearance',
      prop2: 'hideAppBar',
      value: true,
    });
    this.setState({
      showModal: false,
      status: PLAYING,
    });
    answerVideo.pause();
    questionVideo.play();
  }

  handleDiscardPathQuestion() {
    window.history.back();
  }

  handleChange = (name) => (event) => {
    this.props.reduxNestedUpdate({
      prop1: 'user',
      prop2: 'dontShowAgain',
      value: event.target.checked,
    });
  };

  transitionToRecord = () => {
    if (this.state.status === FLASHING) {
      this.setState({
        status: STREAMING,
      });
      console.log('ABOUT TO PLAY');
      questionVideo.pause();
      answerVideo.play();
    } else if (this.state.status === STREAMING) {
      this.startRecording();
    }
  };

  renderButtons() {
    let buttons;
    if (this.state.status === STREAMING) {
      buttons = null;
    } else if (this.state.status === PLAYING) {
      buttons = (
        <div style={styles.authorHeader}>
          <span style={styles.authorHeaderSpan}>
            {this.props.authorDisplayName}
          </span>
        </div>
      );
    } else if (this.state.status === RECORDING) {
      buttons = (
        <div style={styles.recordingButtonsContainer}>
          <span className="flashingREC" style={styles.recIndicator}>
            <span>&#9679;</span> REC
          </span>
          <div style={styles.stopButtonContainer}>
            <div className="spinner" />
          </div>
          <div
            style={{
              ...styles.stopButtonContainer,
              position: 'absolute',
              bottom: 0,
              left: 0,
            }}
          >
            <button style={styles.stopButton} onClick={this.stopRecording}>
              <i className="material-icons" style={{ fontSize: '2.5rem' }}>
                stop
              </i>
            </button>
          </div>
        </div>
      );
    } else {
      buttons = null;
    }
    return (
      <div
        // In index.css videoOverlay has a transition kicked off by appending flashing:
        className={`videoOverlay ${
          this.state.status === FLASHING ? 'flashing' : ''
        }`}
        id="videoOverlayOpenQuestion"
        style={styles.buttonsContainer}
      >
        {buttons}
      </div>
    );
  }

  render() {
    if (this.state.status === VIDEOLOOPING) {
      return (
        <VideoLoop
          questionSrc={this.props.questionSrc}
          questionerDisplayName={this.props.authorDisplayName}
          answerSrc={window.URL.createObjectURL(superBuffer)}
          answererDisplayName={this.props.displayName}
          windowHeight={this.props.windowHeight}
        />
      );
    } else {
      // Here we show / hide the question and answer video elements based on state.status:
      let questionVideoDisplay;
      let answerVideoDisplay;
      switch (this.state.status) {
        case STREAMING:
          questionVideoDisplay = 'none';
          answerVideoDisplay = 'inline';
          break;
        case RECORDING:
          questionVideoDisplay = 'none';
          answerVideoDisplay = 'inline';
          break;
        case FLASHING:
          questionVideoDisplay = 'inline';
          answerVideoDisplay = 'none';
          break;
        case PLAYING:
          questionVideoDisplay = 'inline';
          answerVideoDisplay = 'none';
          break;
        default:
          break;
      }
      return (
        <div
          className="open-question-container"
          style={styles.openQuestionContainer}
        >
          {/* Only show if the user preferences DOES NOT say dontShowAgain, or if it's a question sent from a link */}
          {this.state.showModal &&
            (this.props.dontShowAgain === false || this.props.pathQuestion) && (
              <div className="pre-open-question-modal">
                <SlideModal showModal={this.state.showModal}>
                  <PreOpenQuestionModalContent
                    signedTermsConditionsAndPolicies={
                      this.props.signedTermsConditionsAndPolicies
                    }
                    authorDisplayName={this.props.authorDisplayName}
                    authStatus={this.props.authStatus}
                    closeModal={() => this.handleCloseModal()}
                    discardPathQuestion={() => this.handleDiscardPathQuestion()}
                    dontShowAgain={this.props.dontShowAgain}
                    initialDontShowAgain={this.state.initialDontShowAgain}
                    handleCheck={(name) => this.handleChange(name)}
                    pathQuestion={this.props.pathQuestion}
                  />
                </SlideModal>
              </div>
            )}
          <div
            className="open-question"
            style={{
              ...styles.container,
              height: this.props.windowHeight || '100%',
            }}
          >
            <TimerStrip
              status={this.state.status}
              timerStripId="openQuestionTimerStrip"
            />
            <div style={styles.videoContainer}>
              <video
                disableremoteplayback="true"
                id="questionVideo"
                style={{
                  ...styles.questionVideoStyle,
                  display: questionVideoDisplay,
                }}
                playsInline
                src={this.props.questionSrc}
                type="video/webm"
              />
              <video
                disableremoteplayback="true"
                id="answerVideo"
                style={{
                  ...styles.answerVideoStyle,
                  display: answerVideoDisplay,
                }}
                playsInline
                muted
                type="video/webm"
              />
              {this.renderButtons()}
            </div>
          </div>
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => {
  const { anonymousUid, authStatus, windowHeight } = state.global;
  const {
    displayName,
    dontShowAgain,
    notificationsToken,
    photoURL,
    signedTermsConditionsAndPolicies,
    uid,
    username,
  } = state.global.user;
  return {
    anonymousUid,
    authStatus,
    dontShowAgain,
    displayName,
    notificationsToken,
    photoURL,
    signedTermsConditionsAndPolicies,
    uid,
    username,
    windowHeight,
  };
};

export default connect(mapStateToProps, {
  enablePointerEvents,
  reduxNestedUpdate,
  reduxUpdate,
})(OpenQuestion);
