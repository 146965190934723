import React from 'react';
import Slide from '@material-ui/core/Slide';

import { appBarHeight } from '../../consts/styles';

const styles = {
  background: {
    position: 'absolute',
    zIndex: 3000,
    display: 'flex',
    justifyContent: 'center',
    width: '100vw',
    background: 'rgba(0,0,0,0.3)',
    overflowY: 'hidden'
  },
  container: {
    width: '100vw',
    height: '100%',
    background: 'white',
    overflowY: 'scroll'
  }
};

export function SlideContainer(props) {
  const backgroundTop = props.inSwipeableView ? '0' : `${appBarHeight}px`;
  return (
    <Slide
      direction={props.direction || 'up'}
      in={props.showModal}
      mountOnEnter
      unmountOnExit
    >
      <div style={{...styles.background, top: backgroundTop, height: (props.windowHeight-appBarHeight) }}>
        <div style={ styles.container }>
          {props.children}
        </div>
      </div>
    </Slide>
  );
}
