import React from 'react';
import { defaultBoxShadow } from '../../consts/styles';
import { primary } from '../../consts/colors';

const style = {
  pointerEvents: 'auto', // Buttons always enabled
  margin: '0 auto',
  padding: '.4rem',
  borderRadius: '5px',
  border: '1px solid white',
  boxShadow: defaultBoxShadow,
  width: 'inherit'
};

export function MyButton(props) {
  // const color = props.color || primary;
  let color;
  if (props.disabled) {
    color = '#ababab'
  } else {
    color = props.color || primary;
  }
  return (
    <button 
      disabled={props.disabled}
      style={{...style, background: color }}
      onClick={props.handleClick}
    >
      {props.text}
    </button>
  );
}
