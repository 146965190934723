import React from 'react';
import { defaultBoxShadow } from '../../consts/styles';
import { primaryDark } from '../../consts/colors';

const defaultPhotoWidth = '3.7rem';
const styles = {
  profilePhoto: {
    width: defaultPhotoWidth,
    height: defaultPhotoWidth,
    borderRadius: '50%',
    border: '1px solid white',
    boxShadow: defaultBoxShadow,
  },
  profileIconContainer: {
    width: defaultPhotoWidth,
    height: defaultPhotoWidth,
    background: primaryDark,
    borderRadius: '50%',
    border: '1px solid white',
    boxShadow: defaultBoxShadow,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  profileIcon: {
    color: 'white',
    fontSize: '2.8rem',
  },
};

export function ProfilePhoto(props) {
  return (
    <div>
      {(props.photoURL && (
        <img
          src={props.photoURL}
          alt="profile"
          style={{
            ...styles.profilePhoto,
            width: props.photoWidth || defaultPhotoWidth,
            height: props.photoWidth || defaultPhotoWidth,
          }}
        />
      )) || (
        <div
          style={{
            ...styles.profileIconContainer,
            width: props.photoWidth || defaultPhotoWidth,
            height: props.photoWidth || defaultPhotoWidth,
          }}
        >
          <i className="material-icons" style={styles.profileIcon}>
            person
          </i>
        </div>
      )}
    </div>
  );
}
