import React from 'react';
import { charcoal } from '../../consts/colors';

export function IAcceptLegalLinks() {
  return (
    <p style={{ fontSize: '14px' }}>
      I have read and accept the
      &nbsp;<a target="_blank" rel="noopener noreferrer" style={{ color: charcoal}} href="https://www.websitepolicies.com/policies/view/xS9FNWaa">Terms and Conditions</a>,
      &nbsp;<a target="_blank" rel="noopener noreferrer" style={{ color: charcoal}} href="https://www.websitepolicies.com/policies/view/IlUuhX9y">Privacy Policy</a>,
      &nbsp;and&nbsp;<a target="_blank" rel="noopener noreferrer" style={{ color: charcoal}} href="https://www.websitepolicies.com/policies/view/LC8bAwR7">Disclaimer</a>.
    </p>
  );
}
