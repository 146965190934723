export const ADD_FRIEND = 'add_friend';
export const DISABLE_POINTER_EVENTS = 'disable_pointer_events';
export const ENABLE_POINTER_EVENTS = 'enable_pointer_events';
export const HANDLE_HASH_CHANGE = 'handle_hash_change';
export const MESSAGES_GET_SUCCESS = 'messages_get_success';
export const REDUX_NESTED_UPDATE = 'redux_nested_update';
export const REDUX_UPDATE = 'redux_update';
export const UPDATE_SHOW_TABS = 'update_show_tabs';
export const UPDATE_USER_DATA = 'update_user_data';
export const UPDATE_WINDOW_HEIGHT = 'update_window_height';
