import React from 'react';
import { connect } from 'react-redux';
import { SlideContainer } from './common/SlideContainer';
import Conversation from './Conversation';
import {
  disablePointerEvents,
  reduxUpdate,
  reduxNestedUpdate,
} from '../actions/Actions';
import { friendsFilter } from '../utility/functions/misc';

import {
  charcoal,
  lightCharcoal,
  lightGray,
  beige,
  blue,
  primary,
  gray,
  primaryLight,
} from '../consts/colors';
import { appBarHeight } from '../consts/styles';
import {
  updateURLFragmentString,
  createConvoUid,
} from '../utility/functions/misc';
import { updateLastContact } from '../utility/functions/cloudFirestoreFunctions';

// const firebase = require("firebase");
// Required for side-effects
require('firebase/firestore');

var db;

const styles = {
  explanation: {
    color: gray,
    textAlign: 'left',
    WebkitFontSmoothing: 'antialiased',
    margin: '2rem 1rem',
  },
  dialogStyle: {
    width: '100%',
    maxWidth: 'none',
  },
  block: {
    padding: `5px 10px ${appBarHeight + 5}px`,
  },
  messageBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '.8rem 0',
    borderBottom: `1px solid ${lightGray}`,
    color: lightCharcoal,
    fontWeight: 300,
  },
  nameContainer: {
    WebkitFontSmoothing: 'antialiased',
    display: 'flex',
    fontWeight: '400',
    flexDirection: 'column',
    justifyContent: 'space-around',
    marginLeft: '8px',
    whiteSpace: 'pre-wrap',
    color: charcoal,
  },
  rightContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'flex-end',
    width: '4rem',
  },
};

let staleConvos = false;
class MessageBox extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: true,
      selectedFriendUid: null,
    };
  }

  componentDidMount() {
    db = window.firestore;

    this.fetchConvos();
  }

  componentDidUpdate = (prevProps, prevState) => {
    // stale Convos handles the rare case when a user logs out of one account and into another:
    if (prevProps.uid !== this.props.uid) {
      staleConvos = true;
    }
    if (staleConvos && this.props.uid === this.props.friendsObject.thisUserId) {
      this.fetchConvos();
      staleConvos = false;
    }
  };

  handleConversationUnmount = (unansweredQ) => {
    if (!this.state.selectedFriendUid) {
      return;
    }
    const convoUid = createConvoUid(
      this.props.uid,
      this.state.selectedFriendUid
    );
    let updateStatus = unansweredQ ? 'unansweredQ' : 'upToDate';
    updateLastContact(
      convoUid,
      new Date(),
      updateStatus,
      this.props.uid,
      this.props.notificationsToken
    );
    this.setState({ selectedFriendUid: null });
  };

  fetchConvos = () => {
    if (this.unsubscribeConvos) {
      this.unsubscribeConvos();
    }
    const convosRef = db.collection('convos');
    const { uid } = this.props;
    this.unsubscribeConvos = convosRef
      .where('convoUsers', 'array-contains', uid)
      .orderBy('lastContact', 'desc')
      // .limit(20) // TODO implement limit and allow user to click to load more conversations (friends)
      .onSnapshot((querySnapshot) => {
        if (querySnapshot.empty) {
          this.setState({ convos: null });
        } else {
          let convos = [];
          let unseenText = false;
          let unseenComplete = false;
          let unseenQuestion = false;
          querySnapshot.forEach((doc) => {
            let friendUid;
            let convo = doc.data();
            // Logic to target the friend's uid in the convo:
            if (convo.convoUsers[0] === uid) {
              friendUid = convo.convoUsers[1];
            } else {
              friendUid = convo.convoUsers[0];
            }

            let iconName = '';
            let iconColor = '';

            if (convo[uid]) {
              const { completeUnseen, questionUnseen, textUnseen } = convo[uid];

              if (textUnseen) {
                // New Text
                iconName = 'chat_bubble';
                iconColor = blue;
                unseenText = true;
              }
              if (completeUnseen) {
                // Unseen Response
                iconName = 'play_circle_filled';
                iconColor = blue;
                unseenComplete = true;
              }
              if (questionUnseen) {
                // New Question
                iconName = 'voice_chat';
                iconColor = primary;
                unseenQuestion = true;
              }
              convo.iconName = iconName;
              convo.iconColor = iconColor;
            }

            // If the friend is found in redux's friendsObject, configure as convo and push onto convos array:
            if (this.props.friendsObject[friendUid]) {
              convo.friendUid = friendUid;
              convo.friendUsername = this.props.friendsObject[
                friendUid
              ].username;
              convo.friendDisplayName = this.props.friendsObject[
                friendUid
              ].displayName;
              convos.push(convo);
            } else {
              // Anonymous responses fall in here:
              convo.anonymousResponse = true;
              convos.push(convo);
            }
          });

          // Here we want to set the type of unseen message in redux so the message box tab can show the appropriate badge (and color)
          let mostImportantUnseen = unseenText ? 'text' : null;
          mostImportantUnseen = unseenComplete
            ? 'complete'
            : mostImportantUnseen;
          mostImportantUnseen = unseenQuestion
            ? 'question'
            : mostImportantUnseen;

          if (mostImportantUnseen !== this.props.mostImportantUnseen) {
            this.props.reduxNestedUpdate({
              prop1: 'appearance',
              prop2: 'mostImportantUnseen',
              value: mostImportantUnseen,
            });
          }
          this.props.reduxNestedUpdate({
            prop1: 'appearance',
            prop2: 'convosLength',
            value: convos.length,
          });
          this.setState({
            convos,
          });
        }
      });
  };

  handleConvoClick(friendUid, friendDisplayName, friendUsername) {
    this.setState({
      selectedFriendUid: friendUid,
      selectedFriendDisplayName: friendDisplayName,
    });
    updateURLFragmentString('convo');
    const value = friendDisplayName || friendUsername;
    this.props.reduxUpdate({
      prop: 'appearance',
      value: {
        ...this.props.appearance,
        appBarText1: value,
        appBarText2: '',
      },
    });
  }

  handleAnonymousInfoClick = () => {
    const snackbar = {
      open: true,
      message:
        '"Anonymous Response" indicates someone responded to you without logging in or signing up',
      duration: 3000,
    };
    return this.props.reduxUpdate({
      prop: 'snackbar',
      value: snackbar,
    });
  };

  handleAnonymousResponseClick(questionSrc, answerSrc) {
    const srcUrls = [questionSrc, answerSrc];
    const questionerDisplayName = this.props.displayName;
    const answererDisplayName = this.props.friendDisplayName;
    const value = {
      srcUrls,
      questionerDisplayName,
      answererDisplayName,
    };
    this.props.reduxUpdate({
      prop: 'videoLoop',
      value,
    });
    updateURLFragmentString('unauthed-video-loop');
  }

  calculateTimeDifference(messageDate) {
    const now = new Date(Date.now());
    var msElapsed = Math.abs(now - messageDate);

    var date = new Date(messageDate);

    const minsElapsed = Math.floor(msElapsed / (1000 * 60));
    const hoursElapsed = Math.floor(msElapsed / (1000 * 60 * 60));
    const daysElapsed = Math.floor(msElapsed / (1000 * 60 * 60 * 24));

    if (daysElapsed > 3) {
      return new Intl.DateTimeFormat('en-US', {
        month: 'short',
        day: 'numeric',
      }).format(date);
    } else if (daysElapsed > 1) {
      return `${daysElapsed} days`;
    } else if (daysElapsed === 1) {
      return `1 day`;
    } else if (hoursElapsed > 1) {
      return `${hoursElapsed} hrs`;
    } else if (hoursElapsed === 1) {
      return `1 hr`;
    } else if (minsElapsed > 1) {
      return `${minsElapsed} mins`;
    } else return 'just now';
  }

  renderConvos() {
    if (this.state.convos && this.state.convos[0]) {
      let filteredConvos;
      if (this.props.searchInputValue === '') {
        // Skip the filter process
        filteredConvos = this.state.convos;
      } else {
        const filteredFriends = friendsFilter(
          this.props.friendsArray,
          this.props.searchInputValue
        );
        filteredConvos = this.state.convos.filter((c) => {
          return filteredFriends.map((ff) => ff.uid).includes(c.friendUid);
        });
      }
      return (
        <div>
          {filteredConvos.map((convo) => {
            const timeElapsed = this.calculateTimeDifference(
              convo.lastContact.toMillis()
            );
            const { iconName, iconColor } = convo;
            if (convo.anonymousResponse) {
              if (this.props.appearanceType === 'search') {
                return null;
              } else {
                return (
                  <div
                    key={convo.lastContact.toMillis()}
                    style={styles.messageBox}
                  >
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <div style={{ ...styles.nameContainer }}>
                        <span>{`Anonymous\nResponse `}</span>
                      </div>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <div
                        style={{
                          width: '3.2rem',
                          height: '3.2rem',
                          borderRadius: '10px',
                          border: '1px solid white',
                          boxShadow: '-2px 3px 18px rgba(0, 0, 0, .3)',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          background: blue,
                          margin: '0 1.2rem 0 -1.5rem',
                        }}
                        onClick={() =>
                          this.handleAnonymousResponseClick(
                            convo.questionSrc,
                            convo.answerSrc
                          )
                        }
                      >
                        <i
                          className="material-icons"
                          style={{
                            width: '2.5rem', // Should equal icon's fontSize
                            color: 'white',
                            fontSize: '2.5rem',
                          }}
                        >
                          play_circle_outlined
                        </i>
                      </div>
                      <div onClick={() => this.handleAnonymousInfoClick()}>
                        <i
                          className="material-icons"
                          style={{
                            width: '1.3rem', // Should equal icon's fontSize
                            color: 'grey',
                            marginTop: '-0.8rem',
                            fontSize: '1.3rem',
                          }}
                        >
                          info
                        </i>
                      </div>
                    </div>
                    <div style={styles.rightContainer}>
                      <i
                        className="material-icons"
                        style={{ color: iconColor }}
                      >
                        {iconName}
                      </i>
                      <span style={{ fontSize: '0.8rem' }}>{timeElapsed}</span>
                    </div>
                  </div>
                );
              }
            }
            return (
              <div
                key={convo.lastContact.toMillis()}
                style={styles.messageBox}
                onClick={() => {
                  this.handleConvoClick(
                    convo.friendUid,
                    convo.friendDisplayName,
                    convo.friendUsername
                  );
                }}
              >
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <i
                    className="material-icons"
                    style={{ fontSize: '48px', color: beige }}
                  >
                    account_circle
                  </i>
                  <div style={styles.nameContainer}>
                    <span>{convo.friendDisplayName}</span>
                    <span>{convo.friendUsername}</span>
                  </div>
                </div>
                <div style={styles.rightContainer}>
                  <i className="material-icons" style={{ color: iconColor }}>
                    {iconName}
                  </i>
                  <span style={{ fontSize: '0.8rem' }}>{timeElapsed}</span>
                </div>
              </div>
            );
          })}
        </div>
      );
    } else {
      return (
        <div style={styles.explanation}>
          <p>Messages will appear here.</p>
        </div>
      );
    }
  }

  render() {
    const showModal =
      this.props.appearanceType && this.props.appearanceType.includes('convo')
        ? true
        : false;
    return (
      <div>
        <SlideContainer
          windowHeight={this.props.windowHeight}
          inSwipeableView={true}
          showModal={showModal}
        >
          <Conversation
            closeModal={() => this.handleCloseModal()}
            friendUid={this.state.selectedFriendUid}
            friendDisplayName={this.state.selectedFriendDisplayName}
            onConversationUnmount={this.handleConversationUnmount}
            calculateTimeDifference={this.calculateTimeDifference}
          />
        </SlideContainer>
        <div style={styles.block}>
          {this.renderConvos()}
          {
            <div style={styles.explanation}>
              {this.props.convosLength > 10 && (
                <p>The searchbar above filters your conversations</p>
              )}
              <p style={{ marginBottom: '0.5rem' }}>
                To connect with someone new, make a new video on the next tab
              </p>
              <p style={{ textAlign: 'center' }}>
                <i
                  className="material-icons"
                  style={{
                    color: primaryLight,
                    fontSize: '2.5rem',
                  }}
                >
                  arrow_right_alt
                </i>
              </p>
            </div>
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    friendsArray,
    friendsObject,
    searchInputValue,
    windowHeight,
  } = state.global;
  const { notificationsToken, uid } = state.global.user;
  const {
    appearanceType,
    convosLength,
    unseenMessage,
  } = state.global.appearance;
  const { appearance } = state.global;
  return {
    appearance,
    appearanceType,
    convosLength,
    friendsArray,
    friendsObject,
    notificationsToken,
    searchInputValue,
    uid,
    unseenMessage,
    windowHeight,
  };
};

export default connect(mapStateToProps, {
  disablePointerEvents,
  reduxUpdate,
  reduxNestedUpdate,
})(MessageBox);
