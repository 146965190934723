import React from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Slide from '@material-ui/core/Slide';

import { primary, gray } from './../consts/colors';
import { appBarHeight } from './../consts/styles';
import UserListItem from './common/UserListItem';
import { friendsFilter, createConvoUid } from '../utility/functions/misc';
import { reduxUpdate } from '../actions/Actions';
import { HorizontalLoader } from './common/HorizontalLoader';
import { updateLastContact } from '../utility/functions/cloudFirestoreFunctions';
import { minFriendsToShowFilter } from '../consts/config';

const styles = {
  container: {
    position: 'absolute',
    top: 0,
    zIndex: 1000,
    background: 'white',
    width: '100%',
  },
  copyButtonText: {
    background: primary,
    color: 'white',
    boxShadow: 'rgba(0, 0, 0, 0.3) -3px 3px 5px',
  },
  copySection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '2px 10px 8px',
    paddingBottom: '13px',
  },
  closeDialogButtonContainer: {
    position: 'absolute',
    right: '0',
  },
  DialogContent: {
    overflowX: 'hidden',
    padding: '0',
  },
  displayedUrl: {
    fontSize: '12px',
    color: 'grey',
    margin: '3px',
  },
  textFieldContainer: {
    padding: '0 8px',
  },
  recipientNamesContainer: {
    overflowX: 'auto',
    whiteSpace: 'nowrap',
    background: primary,
    color: 'white',
    padding: '5px',
  },
  sendVidExplanation: {
    color: gray,
    textAlign: 'left',
    WebkitFontSmoothing: 'antialiased',
    margin: '2.5rem 1.5rem 4rem',
  },
};

const shareUrl = `${window.location.origin}?share=` // Should be http://localhost:3000/?share= locally or https://jellyjam.io/?share= in prod

function copyToClipboard(link) {
  // Here we copy the link to the clipboard
  // Create new element
  var el = document.createElement('textarea');
  // Set value (string to be copied)
  el.value = link;
  // Set non-editable to avoid focus and move outside of view
  el.setAttribute('readonly', '');
  el.style = { position: 'absolute', left: '-9999px' };
  document.body.appendChild(el);
  // Select text inside element
  el.select();
  // Copy text to clipboard
  document.execCommand('copy');
  // Remove temporary element
  document.body.removeChild(el);
}

class SendVideoDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      textField: '',
      recommendationsArray: null,
      recipientsArray: [],
      sendingArray: [],
    };
    this.handleSendButtonClick = this.handleSendButtonClick.bind(this);
  }

  componentDidUpdate(prevProps) {
    // Target when the user hits the X, or the browser's back button:
    if (
      prevProps.appearanceType === 'send-to' &&
      this.props.appearanceType === 'review'
    ) {
      this.setState({
        ...this.state,
        recipientsArray: [],
        recommendationsArray: this.props.friendsArray,
        sendingArray: [],
        textField: '',
      });
    }
  }

  textFieldChange = (event) => {
    const recommendationsArray = friendsFilter(
      this.props.friendsArray,
      event.target.value
    );

    this.setState({
      textField: event.target.value,
      recommendationsArray,
    });
  };

  handleCopyButtonClick = () => {
    const shareLink = `${shareUrl}${this.props.shareRef}`;
    copyToClipboard(shareLink);
    // Pop up a snackbar in Home component (handled by redux):
    const snackbar = {
      open: true,
      message: 'Copied to Clipboard',
    };
    this.props.reduxUpdate({
      prop: 'snackbar',
      value: snackbar,
    });
  };

  handleSendButtonClick = async (displayName, recipientUid, username) => {
    if (this.state.recipientsArray.length >= 3) {
      const snackbar = {
        open: true,
        message: 'Limit 3 recipients',
      };
      this.props.reduxUpdate({
        prop: 'snackbar',
        value: snackbar,
      });
      return;
    }
    if (this.state.sendingArray.includes(recipientUid)) {
      // error
      return;
    } else {
      this.setState((state) => {
        const sendingArray = state.sendingArray.concat(recipientUid);
        return { sendingArray };
      });
    }

    console.log('PREPARE TO SEND');
    const db = window.firestore;
    const convoUid = createConvoUid(this.props.uid, recipientUid);
    const date = new Date();
    const message = {
      author: this.props.uid,
      authorDisplayName: this.props.displayName,
      type: 'question',
      questionSrc: this.props.downloadURL,
      date,
    };
    db.collection('convos')
      .doc(convoUid)
      .collection('messages')
      .add(message)
      .then((docRef) => {
        console.log('Document written with ID: ', docRef.id);

        // Update the last contact for conversation
        updateLastContact(
          convoUid,
          date,
          'question',
          this.props.uid,
          this.props.notificationsToken,
          recipientUid
        );

        if (this.state.recipientsArray.length === 1) {
          // the array length is 1, but we're about to update it to 2. Warn the user only 1 send left
          const message = '1 send left, limit of 3 total';
          let snackbar = {
            open: true,
            message,
          };
          this.props.reduxUpdate({
            prop: 'snackbar',
            value: snackbar,
          });
        }

        this.setState((state) => {
          const recipientsArray = state.recipientsArray.concat(recipientUid);
          return { recipientsArray };
        });
      })
      .catch(function(error) {
        console.error('Error adding document: ', error);
      });
  };

  renderRecommendationsArray() {
    let recommendationsArray;
    let explanationContent = ( // Most common case when user has at least one friend displayed
      <div style={styles.sendVidExplanation}>
        <p>
          These are the users you've connected with previously. You can send to
          them directly in the app
        </p>
        <p>
          To connect with someone new, copy the link and send it outside this
          app
        </p>
      </div>
    );
    if (this.state.recommendationsArray) {
      recommendationsArray = this.state.recommendationsArray;
      if (this.state.recommendationsArray.length === 0) {
        // If user filters out all friends in the search bar thing:
        explanationContent = (
          <div style={styles.sendVidExplanation}>
            <p>
              To connect with someone new, copy the link and send it outside
              this app
            </p>
          </div>
        );
      }
    } else {
      recommendationsArray = this.props.friendsArray;
      if (this.props.friendsArray?.length === 0) {
        // If user has no friends, (first time user):
        explanationContent = (
          <div style={styles.sendVidExplanation}>
            <p>
              To start a connection, copy the link and send to a friend anyway
              you like, outside this app
            </p>
            <p>
              Once you connect with another user, you'll see them here and can
              send to them directly
            </p>
          </div>
        );
      }
    }

    return (
      <div>
        {recommendationsArray &&
          recommendationsArray.map((recommendation) => {
            const { displayName, photoURL, uid, username } = recommendation;
            let status = 'loading';
            if (this.props.shareRef) {
              status = 'send';
            }
            if (this.state.sendingArray.includes(uid)) {
              status = 'sending';
            }
            if (this.state.recipientsArray.indexOf(uid) !== -1) {
              status = 'sent';
            }
            return (
              <div key={uid}>
                <UserListItem
                  handleSendButtonClick={this.handleSendButtonClick}
                  displayName={displayName}
                  photoURL={photoURL}
                  username={username}
                  uid={uid}
                  key={uid}
                  status={status}
                />
              </div>
            );
          })}
        {explanationContent}
      </div>
    );
  }

  render() {
    return (
      <Slide
        direction="up"
        in={this.props.openSendVideo}
        mountOnEnter
        unmountOnExit
      >
        <div
          style={{
            ...styles.container,
            height: this.props.windowHeight - appBarHeight,
          }}
        >
          <div style={styles.DialogContent}>
            <div style={styles.copySection}>
              <p>Copy the link, send your video to anyone</p>
              <p style={styles.displayedUrl}>
                {shareUrl}
                {this.props.shareRef}
              </p>
              <div style={styles.link}>
                {/* Only show copy button when shareRef is available in props */}
                {(this.props.shareRef && (
                  <div style={styles.copyButton}>
                    <Button
                      style={styles.copyButtonText}
                      onClick={() => this.handleCopyButtonClick()}
                    >
                      <i
                        className="material-icons"
                        style={{ fontSize: '2rem' }}
                      >
                        filter_none
                      </i>
                      &nbsp;Copy Link
                    </Button>
                  </div>
                )) || (
                  <div style={{ minHeight: '3rem' }}>
                    <HorizontalLoader height="short" />
                  </div>
                )}
              </div>
            </div>
            {this.props.friendsArray?.length > minFriendsToShowFilter && (
              <div style={styles.textFieldContainer}>
                <TextField
                  autoComplete="off"
                  margin="dense"
                  id="name"
                  label="Filter"
                  type="email"
                  fullWidth
                  onChange={this.textFieldChange}
                  value={this.state.textField.value}
                />
              </div>
            )}
            {this.renderRecommendationsArray()}
          </div>
        </div>
      </Slide>
    );
  }
}

const mapStateToProps = (state) => {
  const { friendsArray, windowHeight } = state.global;
  const { displayName, notificationsToken, uid } = state.global.user;
  const { appearanceType } = state.global.appearance;
  return {
    appearanceType,
    displayName,
    friendsArray,
    notificationsToken,
    uid,
    windowHeight,
  };
};

export default connect(mapStateToProps, { reduxUpdate })(SendVideoDialog);
