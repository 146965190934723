// Import FirebaseAuth and firebase.
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/messaging';
import 'firebase/storage';
import nanoid from 'nanoid';
import Button from '@material-ui/core/Button';
import { Snackbar, Checkbox } from '@material-ui/core';

import Home from './Home';
import {
  reduxNestedUpdate,
  reduxUpdate,
  handleHashChange,
  updateWindowHeight,
} from './actions/Actions';
import Profile from './components/Profile';
import SplashScreen from './components/SplashScreen';
import { updateUser } from './utility/functions/cloudFirestoreFunctions';

import {
  appName,
  cameraMicExplanation,
  notificationsExplanation,
  permissionsIntro,
  publicWiFiMessage,
} from './consts/phrases';
import { defaultBoxShadow } from './consts/styles';
import { SlideModal } from './components/common/SlideModal';
import {
  charcoal,
  primary,
  backgroundBright,
  primaryDark,
  primaryMidDark,
  lightCharcoal,
} from './consts/colors';
import {
  fullscreenPortraitMode,
  updateURLFragmentString,
  friendsObjectToSortedArray,
} from './utility/functions/misc';
import OpenQuestion from './components/OpenQuestion';
import { fetchFriends } from './utility/functions/cloudFirestoreFunctions';
import { DummyAppBar } from './components/common/DummyAppBar';
import { MyButton } from './components/common/MyButton';
import { IAcceptLegalLinks } from './components/common/IAcceptLegalLinks';
import { checkCameraCompatibility, getCameraStream } from './utility/functions/cameraFunctions';

// Initialize Firebase
var config = {
  apiKey: 'AIzaSyB0WMWNGx6-9v2B9WMC9A_ZbPFrjxVWv8Y',
  authDomain: 'bouncer-cbd3a.firebaseapp.com',
  databaseURL: 'https://bouncer-cbd3a.firebaseio.com',
  projectId: 'bouncer-cbd3a',
  storageBucket: 'bouncer-cbd3a.appspot.com',
  messagingSenderId: '114148836745',
  appId: '1:114148836745:web:09bc4bb0de206de79b4d58',
};
firebase.initializeApp(config);
// we can check in the console log which firebase products are included in the firebase object (auth for one)
console.log('firebase: ', firebase);

let messaging;
try {
  messaging = firebase.messaging();
  messaging.onMessage((payload) => {
    console.log('Message received bob. ', payload);
  });
} catch (error) {
  console.error('Error executing messaging.onMessage(). Error: ', error);
  // expected output: ReferenceError: nonExistentFunction is not defined
  // Note - error messages will vary depending on browser
}


var firestore;

const styles = {
  button: {
    background: primary,
    color: 'white',
    boxShadow: defaultBoxShadow,
    marginTop: '1.5rem',
  },
  contentContainer: {
    padding: '2rem',
    fontWeight: 400,
    textAlign: 'left',
    color: charcoal,
    background: backgroundBright,
  },
  downloadButton: {
    background: '#2196F3',
    color: 'white',
    border: '1px solid white',
    boxShadow: defaultBoxShadow,
    width: '100%',
    marginBottom: '1rem',
    justifyContent: 'space-between',
  },
  explanationDiv: {
    display: 'flex',
    alignItems: 'center',
    padding: '1.5rem 0',
    lineHeight: '20px',
    borderBottom: '1px solid #d0d0d0',
  },
  explanationIcon: {
    fontSize: '2.3rem',
    color: primaryMidDark,
    width: '4rem',
  },
  p: {
    textAlign: 'center',
    color: lightCharcoal,
    marginTop: '5px',
  },
  portraitButtonContainer: {
    background: '#000000b3',
    height: '200vh',
    width: '100vw',
    position: 'absolute',
    top: 0,
    overflowY: 'hidden',
    zIndex: 9000,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  portraitButtonModal: {
    background: backgroundBright,
    width: 'fit-content',
    marginTop: '1rem',
    padding: '1rem',
    borderRadius: '5px',
  },
  portraitButton: {
    background: primary,
    color: 'white',
    marginTop: '1rem',
    border: '1px solid white',
  },
  splashContainer: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: 2000,
    background: 'white',
  },
  signInContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '100%',
    background: backgroundBright,
    color: primaryDark,
  },
};

const splashMinTime = 2200; //milliseconds
const splashFadeIn = 2200; // Should be at least splashMinTime
const splashFadeOut = 1600; // milliseconds

let splashFadeVisible = true;

// Ensures windowWidth is accurate if app loads in landscape:
const testWidth = window.innerWidth;
const testHeight = window.innerHeight;

const loadedInLandscape = testWidth > testHeight;
let initialSnackbar;

let mediaSource = null;
  try {
    mediaSource = new MediaSource();
  } catch (err) {
    console.log('Error with new MediaSource(): ', err)
  }
let fbShareRef;
let pathQuestion;

class Auth extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // User can't proceed until camera and mic permissions are granted
      cameraPermission: false,
      loadedInLandscape,
      microphonePermission: false,
      mountSplashOnDOM: true,
      isSignedIn: false, // Local signed-in state.
      shareRef: '',
      splash: true,
      splashMinTimeRunning: true,
      unsupportedBrowserFeatures: new Set(),
    };
  }

  // Configure FirebaseUI.
  uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: 'popup',
    // We will display Google and Phone as auth providers.
    signInOptions: [
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      firebase.auth.PhoneAuthProvider.PROVIDER_ID,
    ],
    callbacks: {
      // Avoid redirects after sign-in.
      // signInSuccessWithAuthResult: () => false,
      signInSuccessWithAuthResult: function(authResult, redirectUrl) {
        // Process result. This will not trigger on merge conflicts.
        // On success redirect to signInSuccessUrl.
        console.log('authResult: ', authResult);
        return true; // return false to avoid redirects?
      },
      signInFailure: function(error) {
        console.log('signInFailure: ', error);
      },
    },
  };

  componentDidMount = async () => {
    document.addEventListener('visibilitychange', (ev) => {
      if (document.visibilityState === 'visible' && !window.stream) {
        if (checkCameraCompatibility()) {
          getCameraStream(true);
        } else {
          this.setState(({ unsupportedBrowserFeatures }) => ({
            unsupportedBrowserFeatures: new Set(unsupportedBrowserFeatures).add(
              'GetUserMedia'
            ),
          }));
        }
      }
    });

    window.addEventListener('beforeunload', this.onUnmount, false);

    try {
      if (Notification.permission !== 'default') {
        this.askForNotificationsPermission();
      }
    } catch (err) {
      console.log('Error retrieving Notification: ', err)
      this.setState({ notificationsToken: 'notifications-unavailable' });
    }

    window.onhashchange = () => {
      this.props.handleHashChange(window.location.hash);
    };
    window.location.hash = '1menu'; // Loads the app with a slide index of 1 ( puts user on the <Camera /> slide)
    this.props.handleHashChange(window.location.hash);

    // Check Internet Connection:
    if (window.navigator.onLine) {
      console.log('WE ARE ONLINE');
    } else {
      console.log('NO INTERNET CONNECTION');
    }

    // Screen Orientation Stuff:
    if (
      window &&
      window.screen &&
      window.screen.orientation &&
      window.screen.orientation.type
    ) {
      this.setState({ screenOrientation: window.screen.orientation.type });
      window.screen.orientation.onchange = (e) => {
        this.setState({ screenOrientation: e.target.type });
      };
    } else {
      this.setState(({ unsupportedBrowserFeatures }) => ({
        unsupportedBrowserFeatures: new Set(unsupportedBrowserFeatures).add(
          'Screen Orientation'
        ),
      }));
    }

    const fullscreenPortraitMode = document.fullscreenElement ? true : false;
    this.props.reduxUpdate({
      prop: 'fullscreenPortraitMode',
      value: fullscreenPortraitMode,
    });

    document.addEventListener('fullscreenchange', (event) => {
      const fullscreenPortraitMode = document.fullscreenElement ? true : false;
      // If document.fullscreenElement exists, we're in fullscreen mode.
      this.props.reduxUpdate({
        prop: 'fullscreenPortraitMode',
        value: fullscreenPortraitMode,
      });
    });

    // Video Compatibility Stuff:
    const testVideo = document.getElementById('testVideo');
    testVideo.addEventListener(
      'error',
      (ev) => {
        console.error('Error playing test video, event: ', ev);
        this.setState({
          canPlayVideo: false,
        });
      },
      true
    );
    // Here we know video will work (if needed later):
    // testVideo.addEventListener(
    //   'playing',
    //   () => {
    //     console.log('TEST VIDEO PLAYING')
    //     this.setState(({ unsupportedBrowserFeatures }) => {
    //       const newSet = new Set(unsupportedBrowserFeatures);
    //       newSet.delete('HTML5 Video');
    //       return {
    //        unsupportedBrowserFeatures: newSet
    //       };
    //     });
    //   },
    //   false
    // );
    // testVideo.play(); // above testVideo error is triggered before attempting to play video

    // Window Size Stuff:
    window.addEventListener('resize', this.handleWindowResize.bind(this));

    // Settimeout so splash screen renders for at least the splashMinTime:
    setTimeout(() => {
      this.setState({ splashMinTimeRunning: false });
    }, splashMinTime);

    this.checkCompatibility();

    mediaSource && mediaSource.addEventListener('sourceopen', this.handleSourceOpen, false);
    firestore = firebase.firestore();
    window.firestore = firestore;

    // Question in path param stuff:
    // Strip just the share ref from the pathname
    const queryString = window.location.search;
    let params = new URLSearchParams(queryString);
    let shareRef = params.get('share');
    if (!!shareRef) {
      // Take shareRef out of the URL, replaceState() edits the URL without touching the browser's history:
      if (window.history.replaceState) {
        var newurl =
          window.location.protocol +
          '//' +
          window.location.host +
          window.location.pathname +
          window.location.hash;
        window.history.replaceState({ path: newurl }, '', newurl);
      }
      // make sure the share ref exists in firebase
      fbShareRef = firestore.collection('share').doc(shareRef);
      // We use async await because if it's a valid shareRef, we need to know how to handle the response from onAuthStateChanged
      // (If user isn't logged in, generate a guid and put in localStorage)
      await fbShareRef.get().then((doc) => {
        pathQuestion = doc.data(); // pathQuestion is a variable because we don't want a race condition between setting it in redux / setState, and onAuthStateChanged callback
        if (
          pathQuestion &&
          pathQuestion.storageUrl &&
          pathQuestion.authorUid &&
          pathQuestion.authorDisplayName
        ) {
          // Waiting for onAuthStateChanged to return a user so we can react accordingly
        } else {
          pathQuestion = null;
          const snackbar = {
            open: true,
            message: "We couldn't find a video from the link, here's the app",
          };
          if (this.state.mountSplashOnDOM) {
            initialSnackbar = snackbar;
          } else {
            this.props.reduxUpdate({
              prop: 'snackbar',
              value: snackbar,
            });
          }
        }
      });
    }

    // Listen to the Firebase Auth state and set the local state.
    this.unregisterAuthObserver = firebase.auth().onAuthStateChanged((user) => {
      console.log('authStateChanged, user: ', user);
      this.setState({
        isSignedIn: !!user,
      });
      // Here we can check if pathQuestion has been answered too many times and throw up a snackbar

      // Check if this user has answered this question yet:
      if (
        (pathQuestion &&
          user &&
          pathQuestion.usersThatAnswered.includes(user.uid)) ||
        (pathQuestion &&
          pathQuestion.usersThatAnswered.includes(
            window.localStorage.getItem('anonymousUid')
          ))
      ) {
        this.handleQuestionAlreadyAnswered();
      } else if (pathQuestion) {
        updateURLFragmentString('path-oq');
      }

      // We have an unauthenticated user with a valid question from the URL:
      if (user === null && pathQuestion) {
        this.anonymousAuth();
        return;
      } else if (user === null) {
        this.props.reduxUpdate({
          prop: 'authStatus',
          value: 'newUser',
        });
        return;
      }
      this.loginUser(user); // Only happens when user is authenticated in firebase
    });
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (
      prevState.mountSplashOnDOM === true &&
      this.state.mountSplashOnDOM === false &&
      initialSnackbar
    ) {
      this.props.reduxUpdate({
        prop: 'snackbar',
        value: initialSnackbar,
      });
    }

    if (
      prevProps.appearanceType === 'path-oq' &&
      this.props.appearanceType === 'menu'
    ) {
      pathQuestion = null; // Done with the pathQuestion at this point (or they hit back, either way we're done with it)
    }

    // Checking for uid ensures we have user info in redux, namely the notificationsToken info:
    if (
      this.state.notificationsToken &&
      this.state.notificationsToken !== this.props.notificationsToken &&
      this.props.uid
    ) {
      // Update Token in database and redux:
      updateUser(
        this.props.uid,
        'notificationsToken',
        this.state.notificationsToken
      );
      this.props.reduxNestedUpdate({
        prop1: 'user',
        prop2: 'notificationsToken',
        value: this.state.notificationsToken,
      });
    }
  };

  // Make sure we un-register Firebase observers when the component unmounts.
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowResize);
    window.removeEventListener('beforeunload', this.onUnmount, false);
    this.onUnmount();
  }

  onUnmount = () => {
    this.unregisterAuthObserver();

    // window.stream.getTracks().forEach(function(track) {
    //   track.stop();
    //   console.log('track bob: ', track);
    // });
  };

  handleWindowResize() {
    if (
      this.state.loadedInLandscape &&
      window.innerHeight > window.innerWidth
    ) {
      window.location.reload();
    }
    this.props.updateWindowHeight(window.innerHeight);
  }

  checkCompatibility = () => {
    let incompatibleFeatures = new Set();
    // screen lock, navigator.permissions, play a vid,
    if (!window.screen.orientation) {
      incompatibleFeatures.add('Screen Orientation');
    }
    if (!navigator.permissions) {
      incompatibleFeatures.add('Navigator Permissions');
    }

    this.checkPermissions();

    this.setState(({ unsupportedBrowserFeatures }) => ({
      unsupportedBrowserFeatures: new Set([
        ...unsupportedBrowserFeatures,
        ...incompatibleFeatures,
      ]),
    }));
  };

  checkPermissions = () => {
    if (navigator.permissions) {
      let cameraPermission = null;
      let microphonePermission = null;
      let notificationPermission = Notification?.permission ?? null;
      navigator.permissions
        .query({ name: 'camera' })
        .then((result) => {
          cameraPermission = result.state;
          console.log('cameraPermission: ', cameraPermission);
          result.onchange = () => {
            this.checkPermissions();
          };
          navigator.permissions.query({ name: 'microphone' }).then((result) => {
            microphonePermission = result.state;
            result.onchange = () => {
              this.checkPermissions();
            };
            this.setState({
              cameraPermission,
              microphonePermission,
              notificationPermission,
            });
            if (
              cameraPermission === 'granted' &&
              microphonePermission === 'granted'
            ) {
              if (checkCameraCompatibility()) {
                getCameraStream(true);
              } else {
                this.setState(({ unsupportedBrowserFeatures }) => ({
                  unsupportedBrowserFeatures: new Set(unsupportedBrowserFeatures).add(
                    'GetUserMedia'
                  ),
                }));
              }
            }
          });
        })
        .catch((e) => {
          console.log('CAUGHT EXCEPTION IN PERMISSIONS QUERY: ', e);
          this.setState(({ unsupportedBrowserFeatures }) => ({
            unsupportedBrowserFeatures: new Set(unsupportedBrowserFeatures).add(
              'Navigator Permissions'
            ),
          }));
        });
    } else {
      if (checkCameraCompatibility()) {
        getCameraStream(true);
      } else {
        this.setState(({ unsupportedBrowserFeatures }) => ({
          unsupportedBrowserFeatures: new Set(unsupportedBrowserFeatures).add(
            'GetUserMedia'
          ),
        }));
      }
    }
  };

  askForPermissions = () => {
    // Here we will ask for camera and mic, and/or notifications permissions:
    let {
      cameraPermission,
      microphonePermission,
      notificationPermission,
    } = this.state;
    cameraPermission =
      cameraPermission === 'prompt' ? 'asking' : cameraPermission;
    microphonePermission =
      microphonePermission === 'prompt' ? 'asking' : microphonePermission;
    notificationPermission =
      notificationPermission === 'default' ? 'asking' : notificationPermission;

    this.setState({
      cameraPermission,
      microphonePermission,
      notificationPermission,
    });
    if (checkCameraCompatibility()) {
      getCameraStream(true);
    } else {
      this.setState(({ unsupportedBrowserFeatures }) => ({
        unsupportedBrowserFeatures: new Set(unsupportedBrowserFeatures).add(
          'GetUserMedia'
        ),
      }));
    }
    this.askForNotificationsPermission();
  };

  askForNotificationsPermission = () => {
    if (!!messaging && Notification) {
      Notification.requestPermission()
      .then(() => {
        return messaging.getToken();
      })
      .then((currentToken) => {
        if (currentToken) {
          // Probably use state and redux to know when to update user in db, not sure:
          this.setState({ notificationsToken: currentToken });
        } else {
          // This should never happen
          console.log(
            'XXX No Instance ID token available. Request permission to generate one.'
          );
        }
      })
      .catch((err) => {
        // User chose to block notifications:
        console.log('We got an err: ', err);
        console.log('User probably blocked notifications permission');
        this.setState({ notificationsToken: 'user-blocked-notifications' });
      });
    } else {
      this.setState({ notificationsToken: 'notifications-unavailable' });
    }
  };

  handleSourceOpen = (event) => {
    mediaSource && mediaSource.addSourceBuffer('video/webm; codecs="vp8"');
  };

  anonymousAuth = () => {
    // Currently does not use firebase anonymous auth:
    // Check for anonymousUid in localStorage:
    const { localStorage } = window;
    let anonymousUid = localStorage.getItem('anonymousUid');
    // If there's no anonymousUid in localStorage, make one and set it there
    if (!anonymousUid) {
      anonymousUid = nanoid();
      localStorage.setItem('anonymousUid', anonymousUid);
    }
    this.props.reduxUpdate({
      prop: 'authStatus',
      value: 'anonymousUser',
    });
    this.props.reduxUpdate({
      // Probably will come in handy
      prop: 'anonymousUid',
      value: anonymousUid,
    });
  };

  loginUser = async (user) => {
    const userRef = firestore.collection('users').doc(user.uid);
    window.firestoreUserRef = userRef;
    userRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          // USER EXISTS IN FIREBASE
          console.log('Existing User Data:', doc.data());
          const existingUserData = {
            email: doc.data().email,
            dontShowAgain: doc.data().dontShowAgain || false,
            notificationsToken: doc.data().notificationsToken || null,
            providerId: doc.data().providerId,
            uid: doc.data().uid, // This is the key in Firestore
            username: doc.data().username,
            displayName: doc.data().displayName,
            photoURL: doc.data().photoURL,
            phoneNumber: doc.data().phoneNumber,
            emailVerified: doc.data().emailVerified,
            hasUsername: true,
            signedTermsConditionsAndPolicies: doc.data()
              .signedTermsConditionsAndPolicies,
          };
          this.props.reduxUpdate({
            prop: 'user',
            value: existingUserData,
          });
          this.props.reduxUpdate({
            prop: 'authStatus',
            value: 'knownUser',
          });

          // fetchFriends()
          try {
          window.firestoreUserRef
          .collection('friends')
          .doc('friends')
          .onSnapshot((doc) => {
            const friendsFromDB = doc.data();
              console.log('!! Friends From DB: ', friendsFromDB);
              // Add a friends object to redux, to grab data in messages
              this.props.reduxUpdate({
                prop: 'friendsObject',
                value: { ...friendsFromDB, thisUserId: this.props.uid },
              });
              const friendsArray = friendsObjectToSortedArray(friendsFromDB);
              // Add a friends array to redux, for search and choose recipients
              this.props.reduxUpdate({
                prop: 'friendsArray',
                value: friendsArray,
              });
            })} catch (e) {
              console.log('Error in Fetching Friends: ', e);
            }
        } else {
          // ADD NEW USER TO FIREBASE:
          console.log('New User!');
          const newUserData = {
            email: user.email,
            providerId: user.providerId,
            displayName: user.displayName,
            uid: user.uid,
            photoURL: user.photoURL,
            phoneNumber: user.phoneNumber,
            emailVerified: user.emailVerified,
            hasUsername: false,
          };
          userRef
            .set(newUserData)
            .then((userRef) => {
              // ADD NEW USER DATA TO REDUX:
              this.props.reduxUpdate({
                prop: 'user',
                value: { ...newUserData, uid: user.uid },
              });
              this.props.reduxUpdate({
                prop: 'authStatus',
                value: 'newUser',
              });
              this.props.reduxUpdate({
                prop: 'friendsObject',
                value: { thisUserId: this.props.uid },
              });
            })
            .catch(function(error) {
              console.error('Error adding document: ', error);
            });
          // Couldn't find a way to update based on uid as the key directly in the user data:
          userRef
            .collection('friends')
            .doc('friends')
            .set({});
        }
      })
      .catch(function(error) {
        // PSUEDO CODE HERE:
        // setState({ retry: true })
        // this in component did mount, trigger retry,
        console.log('no user found!'); // TO DO RETRY?
        console.log('Error getting document:', error);
      });
  };

  signOut() {
    firebase.auth().signOut();
  }

  handleQuestionAlreadyAnswered = () => {
    pathQuestion = null;
    const snackbar = {
      open: true,
      message: "You've already responded to the video in the link",
    };
    if (this.state.mountSplashOnDOM) {
      initialSnackbar = snackbar;
    } else {
      this.props.reduxUpdate({
        prop: 'snackbar',
        value: snackbar,
      });
    }
  };

  handleSnackBarClose = (event, reason) => {
    this.props.reduxUpdate({
      prop: 'snackbar',
      value: {
        open: false,
        message: '',
      },
    });
  };

  renderTestVideo = () => {
    // Test to make sure the browser can play video:
    return (
      <div id="momo" style={{ display: 'none' }}>
        <video
          id="testVideo"
          style={{}}
          playsInline
          // src="https://firebasestorage.googleapis.com/v0/b/bouncer-cbd3a.appspot.com/o/do-not-delete%2F1610151095056.webm?alt=media&token=f10c8e17-e245-4cd0-9cc1-eec77451e1d9"
          src={`${process.env.PUBLIC_URL}/test.webm`}
          muted
          type="video/webm"
        />
      </div>
    );
  };

  renderBrowserModal = () => {
    let content = null;
    // These conditionals are ordered by need to address:
    // If Not on mobile: (disabled for developing on localhost)
    if (
      window.navigator.maxTouchPoints < 2 &&
      !(
        window.location.hostname === 'localhost' ||
        window.location.hostname === '127.0.0.1'
      )
    ) {
      content = (
        <div style={styles.contentContainer}>
          <span>{appName} only works on mobile</span>
        </div>
      );
    }
    // If on unsupported browser:
    else if (this.state.unsupportedBrowserFeatures.has('GetUserMedia')) {
      content = (
        <div style={styles.contentContainer}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <i className="material-icons" style={{ color: primaryDark }}>
              warning
            </i>
            <p style={{ ...styles.p, color: primaryDark }}> Whoa.</p>
          </div>
          <p style={styles.p}>
            This browser doesn't support our camera functionality.
          </p>
          <p style={styles.p}>
            For this app we recommend using Google Chrome or Brave Browser.
            {'\n'} Here are some links:
          </p>
          <p style={styles.p}></p>
          <Button
            href="https://www.google.com/chrome/"
            style={styles.downloadButton}
          >
            Chrome Download
            <i className="material-icons" style={{ marginLeft: '2rem' }}>
              launch
            </i>
          </Button>
          <Button
            href="https://brave.com/download/"
            style={styles.downloadButton}
          >
            Brave Download
            <i className="material-icons" style={{ marginLeft: '2rem' }}>
              launch
            </i>
          </Button>
        </div>
      );
    }
    // If User hasn't responded to permissions request
    else if (
      navigator.permissions &&
      (this.state.cameraPermission === 'prompt' ||
      this.state.microphonePermission === 'prompt' ||
      this.state.notificationPermission === 'default')
    ) {
      content = (
        <div style={styles.contentContainer}>
          <span style={{ fontSize: '1.25rem', fontWeight: 500 }}>
            {permissionsIntro}
          </span>
          <br />
          <br />
          <span>{cameraMicExplanation}</span>
          <br />
          <br />
          <span>{notificationsExplanation}</span>
          <br />
          <br />
          <hr />
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <span>Is that cool?</span>
            <div>
              <Button
                style={styles.button}
                onClick={() => this.askForPermissions()}
              >
                Cool
              </Button>
            </div>
          </div>
        </div>
      );
    }
    // User denied permissions request:
    else if (
      navigator.permissions &&
      (this.state.cameraPermission === 'denied' || this.state.microphonePermission === 'denied')
    ) {
      content = (
        <div style={styles.contentContainer}>
          <span>
            It looks like camera and microphone permissions are blocked. You'll
            have to turn them on to continue. Check your browser settings on
            this page.
          </span>
        </div>
      );
    }
    // If browser can't play sample video for some reason (usually public wifi or no connection)
    else if (this.state.canPlayVideo === false) {
      content = (
        <div style={styles.contentContainer}>
          <span>{publicWiFiMessage}</span>
        </div>
      );
    }

    if (content) {
      return (
        <Fragment>
          <div style={{ zIndex: 10000, position: 'absolute' }}>
            <DummyAppBar />
          </div>
          <SlideModal windowHeight={this.props.windowHeight} showModal={true}>
            {content}
          </SlideModal>
        </Fragment>
      );
    }

    if (this.state.screenOrientation !== 'portrait-primary') {
      return (
        <div style={styles.portraitButtonContainer}>
          <div style={styles.portraitButtonModal}>
            <p style={styles.p}>{appName} doesn't jive with landscape.</p>
            <Button
              style={styles.portraitButton}
              onClick={() => fullscreenPortraitMode()}
            >
              Fullscreen Portrait Mode
            </Button>
            <p style={{ ...styles.p, fontSize: '12px' }}>
              (Or turn your phone)
            </p>
          </div>
        </div>
      );
    }
  };

  renderSplashScreen() {
    const { authStatus } = this.props;
    const { mountSplashOnDOM } = this.state;
    // If known user is signed in and the camera is ready, or it's a new user:
    if (
      ((authStatus === 'knownUser' && window.stream) ||
        authStatus === 'newUser' ||
        authStatus === 'anonymousUser') &&
      // And the minimum splash time is reached
      !this.state.splashMinTimeRunning &&
      mountSplashOnDOM
    ) {
      // fade out the splash, and settimeout to remove from DOM:
      splashFadeVisible = false;
      setTimeout(() => {
        this.setState({
          mountSplashOnDOM: false,
        });
      }, splashFadeOut);
    }
    return mountSplashOnDOM ? (
      <SplashScreen
        splash={splashFadeVisible}
        splashFadeIn={splashFadeIn}
        splashFadeOut={splashFadeOut}
      />
    ) : null;
  }

  renderOpenQuestion = () => {
    // Need both path question and uid (including anonymousUid) to continue:
    if (!pathQuestion) {
      return;
    }
    if (!(this.props.uid || this.props.anonymousUid)) {
      return;
    }

    if (window.stream && this.props.appearanceType === 'path-oq') {
      return (
        <OpenQuestion
          fbShareRef={fbShareRef}
          questionSrc={(pathQuestion && pathQuestion.storageUrl) || null}
          authorUid={(pathQuestion && pathQuestion.authorUid) || null}
          authorDisplayName={
            (pathQuestion && pathQuestion.authorDisplayName) || null
          }
          pathQuestion={true}
        />
      );
    }
  };

  authNavigator() {
    const permissionsGranted =
      (this.state.cameraPermission === 'granted' && this.state.microphonePermission === 'granted') ||
      !navigator.permissions;
    // Sets the initial background, the splashScreen then fades in on top (with it's own background):
    if (
      this.props.authStatus === 'searching' ||
      this.state.splashMinTimeRunning ||
      !permissionsGranted
    ) {
      return (
        <div
          style={{
            position: 'absolute',
            zIndex: 1999,
            width: '100%',
            height: '100%',
            background: 'white',
          }}
        />
      );
    } else if (
      this.state.isSignedIn &&
      this.props.username &&
      (this.state.termsAndCondishAccepted ||
        this.props.signedTermsConditionsAndPolicies)
    ) {
      return <Home signOut={this.signOut} />;
    } else if (this.state.isSignedIn && this.props.username) {
      // If user needs to accept Terms and Conditions:
      return (
        <Fragment>
          <DummyAppBar />
          <div
            style={{
              ...styles.contentContainer,
              height: '100%',
              display: 'flex',
              color: charcoal,
              flexDirection: 'column',
            }}
          >
            <p
              style={{
                fontSize: '18px',
                fontWeight: '500',
                margin: '0',
                textAlign: 'center',
              }}
            >
              How it goes
            </p>
            <div style={styles.explanationDiv}>
              <span>
                <i className="material-icons" style={styles.explanationIcon}>
                  voice_chat
                </i>
              </span>
              <p style={{ margin: 0, flex: '1' }}>
                A friend sends you a video message
              </p>
            </div>
            <div style={styles.explanationDiv}>
              <span>
                <i className="material-icons" style={styles.explanationIcon}>
                  camera_enhanced
                </i>
              </span>
              <p style={{ margin: 0 }}>
                After viewing,{' '}
                <b>your camera turns on, records your response,</b> and sends it
                back to your friend.
                <br />
                <br />
                (That's the whole premise)
              </p>
            </div>
            <div style={styles.explanationDiv}>
              <span>
                <i className="material-icons" style={styles.explanationIcon}>
                  groups
                </i>
              </span>
              <p style={{ margin: 0 }}>
                Add friends by sharing url links outside this app
              </p>
            </div>
            <div style={styles.explanationDiv}>
              <span>
                <i className="material-icons" style={styles.explanationIcon}>
                  volunteer_activism
                </i>
              </span>
              <p style={{ margin: 0 }}>
                Please be careful who you send your links to, and who you open
                links from
              </p>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                flex: '1',
                justifyContent: 'center',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  margin: '3rem 0 1rem',
                }}
              >
                <Checkbox
                  checked={this.state.termsAndCondishClicked}
                  onChange={(e) =>
                    this.setState({ termsAndCondishClicked: e.target.checked })
                  }
                  color="primary"
                />
                <IAcceptLegalLinks />
              </div>
              <div style={{ display: 'flex', paddingBottom: '2rem' }}>
                <MyButton
                  handleClick={() => {
                    this.setState({ termsAndCondishAccepted: true });
                    updateUser(
                      this.props.uid,
                      // 'O0n6PLPHQ9WJpTVvorpRYyxcPnI2',
                      'signedTermsConditionsAndPolicies',
                      this.state.termsAndCondishClicked
                      // false
                    );
                  }}
                  disabled={!this.state.termsAndCondishClicked}
                  text="Continue"
                />
              </div>
            </div>
          </div>
        </Fragment>
      );
    } else if (this.state.isSignedIn && !this.props.username) {
      return <Profile signOut={this.signOut} style={{ height: '100%' }} />;
    } else {
      return (
        <div style={styles.signInContainer}>
          <div style={{ flex: '0' }}>
            <DummyAppBar />
          </div>
          <div style={{ flex: '0.9' }}>
            <p style={{ textAlign: 'center', marginBottom: '2rem' }}>
              Welcome, please sign in
            </p>
            <StyledFirebaseAuth
              uiConfig={this.uiConfig}
              firebaseAuth={firebase.auth()}
            />
          </div>
        </div>
      );
    }
  }

  render() {
    console.log('unsupportedBrowserFeatures: ', this.state.unsupportedBrowserFeatures);
    return (
      <div
        style={{
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {this.renderTestVideo()}
        {this.renderBrowserModal()}
        {this.renderSplashScreen()}
        {this.renderOpenQuestion()}
        {this.authNavigator()}
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={this.props.snackbar && this.props.snackbar.open}
          autoHideDuration={this.props.snackbar.duration || 2000}
          onClose={this.handleSnackBarClose}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id="message-id">{this.props.snackbar.message}</span>}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    anonymousUid,
    authStatus,
    snackbar,
    windowHeight,
  } = state.global;
  const { appearanceType } = state.global.appearance;
  const {
    notificationsToken,
    signedTermsConditionsAndPolicies,
    uid,
    username,
  } = state.global.user;
  return {
    anonymousUid,
    appearanceType,
    authStatus,
    notificationsToken,
    signedTermsConditionsAndPolicies,
    snackbar,
    uid,
    username,
    windowHeight,
  };
};

export default connect(mapStateToProps, {
  reduxNestedUpdate,
  reduxUpdate,
  handleHashChange,
  updateWindowHeight,
})(Auth);
