import React from 'react';
import Slide from '@material-ui/core/Slide';

const styles = {
  background: {
    position: 'absolute',
    zIndex: 8000,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: '100vw',
    height: '100vw',
    background: 'rgba(0,0,0,0.5)',
    overflowY: 'hidden'
  },
  container: {
    width: '100%',
    margin: '6rem 0 0',
    background: 'white',
    overflowY: 'scroll',
    height: 'fit-content',
    textAlign: 'center',
    fontWeight: 200,
    lineHeight: 1.4
  }
};

export function SlideModal(props) {
  return (
    <div style={{...styles.background, height: '100%' }}>
      <Slide
        direction={props.direction || 'up'}
        in={props.showModal}
        mountOnEnter
        unmountOnExit
      >
        <div style={styles.container}>
          {props.children}
        </div>
      </Slide>
    </div>
  );
}
