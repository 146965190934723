const backgroundBright = '#fffaf7';
const beige = '#d2c4c4';
const charcoal = '#3d3d3d';
const gray = '#828282';
const lightCharcoal = '#4f4f4f';
const lightGray = '#dbdbdb';
const primary = '#d85f8b';
const primaryMidDark = '#b56280';
const primaryDark = '#6b4559';
const primaryLight = '#f06292';
const primarySuperDark = '#8c0649';
const primarySuperLight = '#fce4ec';
const blue = '#85c9ff';

export {
  backgroundBright,
  beige,
  charcoal,
  gray,
  lightCharcoal,
  lightGray,
  primary,
  primaryMidDark,
  primaryDark,
  primaryLight,
  primarySuperDark,
  primarySuperLight,
  blue,
};
