import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import { primaryLight, blue } from '../consts/colors';
import { MyButton } from './common/MyButton';
import {
  warningIntro,
  warningDontOpen,
  warningStep1,
  warningStep2,
  warningStep3,
  warningStep4,
  warningOutro,
} from '../consts/phrases';
import { IAcceptLegalLinks } from './common/IAcceptLegalLinks';

const styles = {
  boldAndSmooth: {
    fontWeight: 500,
    '-webkit-font-smoothing': 'antialiased',
  },
  buttonContainer: {
    display: 'flex',
    padding: '0.5rem 0 1.5rem',
    width: '7rem',
  },
  container: {
    background: 'white',
    pointerEvents: 'auto', // Buttons always enabled
    textAlign: 'center',
    padding: '1rem 1rem 0.25rem',
    fontWeight: '300',
    whiteSpace: 'pre-wrap',
  },
  header: {
    fontSize: '1.25rem',
  },
  headerDiv: {
    textAlign: 'center',
  },
};

export default class PreOpenQuestionModalContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    console.log('!this.props.signedTermsConditionsAndPolicies: ', this.props.signedTermsConditionsAndPolicies)
    const playButtonDisabled =
      this.props.signedTermsConditionsAndPolicies ||
      this.state.termsAndCondishClicked
        ? false
        : true;
    return (
      <div style={styles.container}>
        <div style={styles.headerDiv}>
          <span style={styles.header}>Whoa</span>
          <div>
            <i className="material-icons" style={{ color: primaryLight }}>
              pan_tool
            </i>
          </div>
        </div>
        <div style={{ textAlign: 'left' }}>
          <p style={styles.boldAndSmooth}>{warningIntro}</p>
          <ol style={{ paddingLeft: '2rem' }}>
            <li
              style={{ marginBottom: '0.5rem' }}
            >{`${warningStep1} ${this.props.authorDisplayName} will play.`}</li>
            <li style={{ marginBottom: '0.5rem' }}>
              {warningStep2}
              <span style={styles.boldAndSmooth}>{warningStep3}</span>
            </li>
            <li
              style={{ marginBottom: '0.5rem' }}
            >{`${warningStep4} ${this.props.authorDisplayName}.`}</li>
          </ol>
          <p style={{ marginTop: '1.5rem', textAlign: 'center' }}>
            {warningOutro}
          </p>
          {this.props.pathQuestion && (
            <p style={styles.boldAndSmooth}>{warningDontOpen}</p>
          )}
          {!this.props.signedTermsConditionsAndPolicies && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                margin: '3rem 0 1rem',
              }}
            >
              <Checkbox
                checked={this.state.termsAndCondishClicked}
                onChange={(e) =>
                  this.setState({ termsAndCondishClicked: e.target.checked })
                }
                color="primary"
              />
              <IAcceptLegalLinks />
            </div>
          )}
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
          <div style={styles.buttonContainer}>
            <MyButton
              handleClick={this.props.discardPathQuestion}
              text={this.props.pathQuestion ? 'Discard' : 'Back'}
            />
          </div>
          <div style={styles.buttonContainer}>
            <MyButton
              handleClick={this.props.closeModal}
              text="Play Video"
              color={blue}
              disabled={playButtonDisabled}
            />
          </div>
        </div>
        {/* Disabling the do not show again functionality for now at least */}
        {/* {(this.props.initialDontShowAgain === false) && (this.props.authStatus !== 'anonymousUser') &&
          <FormControlLabel
            control={
              <Checkbox
                checked={this.props.dontShowAgain}
                onChange={this.props.handleCheck('dontShowAgain')}
                value="dontShowAgain"
                color="primary"
              />
            }
            label="Don't show again, play video immediately."
          />
        } */}
      </div>
    );
  }
}
