const download = (recordedBlobs) => {
  var blob = new Blob(recordedBlobs, { type: 'video/webm' });
  var url = window.URL.createObjectURL(blob);
  var a = document.createElement('a');
  a.style.display = 'none';
  a.href = url;
  a.download = 'test.webm';
  document.body.appendChild(a);
  a.click();

  setTimeout(function() {
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }, 100);
};

const constraints = {
  audio: true,
  video: {
    width: window.innerWidth,
    aspectRatio: 1.3, // Prod version. For desktop debugging use:
    // aspectRatio: 1 / 1.3, // Desktop
    facingMode: 'user',
  },
};
//facingMode: "environment" for rear camera

const checkCameraCompatibility = () => {
  if (
    !navigator.mediaDevices &&
    !navigator.getUserMedia &&
    !navigator.webkitGetUserMedia &&
    !navigator.mozGetUserMedia &&
    !navigator.msGetUserMedia) {
    return false;
  }
  return true
}

const getUserMedia = (constraints) => {
  // Check for preferred api:
  if (navigator.mediaDevices) {
    return navigator.mediaDevices.getUserMedia(constraints);
  }

  // If not, try fallbacks...
  var legacyApi = navigator.getUserMedia || navigator.webkitGetUserMedia ||
    navigator.mozGetUserMedia || navigator.msGetUserMedia;

  if (legacyApi) {
    // Make it a promise
    return new Promise((resolve, reject) => {
      legacyApi.bind(navigator)(constraints, resolve, reject);
    });
  }
}

const getCameraStream = (disableAudio) => {
  // disableAudio will immediately disable the audio track, (fixes the phone's sound issue while app is up)
  return new Promise((resolve, reject) => {
    // close existing streams
    if (window.stream) {
      window.stream.getTracks().forEach(function(track) {
        track.stop();
      });
    }

    // This will prompt the user for camera and mic permissions:
    getUserMedia(constraints)
    .then((stream) => {
      if (disableAudio) {
        stream.getTracks().forEach(function(track) {
          if (track.kind === 'audio') {
            track.stop();
            stream.removeTrack(track);
          }
        });
      }
      window.stream = stream; // put the stream on the window object
      resolve('success');
    })
    .catch((err) => {
      console.log('navigator.getUserMedia error: ', err);
      reject(err);
    });
  });
};

const addAudioToMediaStream = () => {
  return new Promise((resolve, reject) => {
    // get a new stream (just audio), get the track, and add that track to the existing stream on the window:
    navigator.mediaDevices
      .getUserMedia({ audio: true }) // just grab audio
      .then((audioStream) => {
        audioStream.getTracks().forEach(function(track) {
          if (track.kind === 'audio') {
            window.stream.addTrack(track);
          }
        });
        resolve('success');
      })
      .catch((err) => {
        console.log('navigator.getUserMedia error: ', err);
        reject(err);
      });
  });
};

const removeAudioFromMediaStream = () => {
  if (window.stream) {
    window.stream.getTracks().forEach(function(track) {
      if (track.kind === 'audio') {
        track.stop();
        window.stream.removeTrack(track);
      }
    });
  }
};

export {
  download,
  addAudioToMediaStream,
  checkCameraCompatibility,
  getCameraStream,
  removeAudioFromMediaStream,
};
