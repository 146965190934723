import React from 'react';
import Fade from '@material-ui/core/Fade';
import { appName1, appName2 } from '../consts/phrases';
import { primary } from '../consts/colors';
import { appBarHeight } from '../consts/styles';

const styles = {
  appName: {
    color: primary,
    fontSize: '1.7rem',
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '4rem',
    marginTop: `${appBarHeight + 16}px`,
    background: 'white',
    borderRadius: '50%',
    height: '17rem',
    width: '17rem',
    boxShadow: '#ffe4ef -2px 2px 14px',
  },
  outerContainer: {
    position: 'fixed',
    zIndex: 2000,
    width: '100%',
    height: '100%',
    background: 'white',
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'column',
    alignItems: 'center',
  },
  innerContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  spacer: {
    flex: 1,
  },
};

export default function SplashScreen(props) {
  return (
    <Fade
      in={props.splash}
      style={{ transformOrigin: '0 0 0' }}
      {...(props.splash
        ? { timeout: props.splashFadeIn }
        : { timeout: props.splashFadeOut })}
    >
      <div style={styles.outerContainer}>
        <div style={styles.innerContainer}>
          <div style={styles.contentContainer}>
            <p style={styles.appName}>
              {appName1}
              <span style={{ fontWeight: 200 }}>{appName2}</span>
            </p>
            <span
              style={{
                fontSize: '0.9rem',
                color: '#757575',
                marginBottom: '0.5rem',
              }}
            >
              be good to each other
            </span>
          </div>
        </div>
        <div style={styles.spacer}></div>
      </div>
    </Fade>
  );
}
