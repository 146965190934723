const appName1 = 'jelly';
const appName2 = 'jam';
const domainExt = '.io';
const appName2WithDomainExt = appName2 + domainExt;
const appName = appName1+appName2;

const permissionsIntro = `We're about to ask for some permissions`;
const cameraMicExplanation = `Camera and microphone are necessary.`
const notificationsExplanation = `We only send a notification when a friend sends you a message.`;

const warningIntro = `Here's the deal:`
const warningStep1 = 'The video from'
const warningStep2 = 'When it ends, '
const warningStep3 = 'your camera turns on and records your response.'
const warningStep4 = 'We send your response back to'
const warningOutro = `Could be fun`
const warningDontOpen = `Do not open this if you don't know the sender.`

const publicWiFiMessage = `We're seeing an issue, it's most likely your internet connection. Public WiFi can block video features. Using mobile data should fix this.`

export {
  appName,
  appName1,
  appName2,
  appName2WithDomainExt,
  permissionsIntro,
  cameraMicExplanation,
  notificationsExplanation,
  publicWiFiMessage,
  warningDontOpen,
  warningIntro,
  warningOutro,
  warningStep1,
  warningStep2,
  warningStep3,
  warningStep4,
};
