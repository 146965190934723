import React from 'react';

import { appBarHeight } from '../../consts/styles';
import { appName1, appName2WithDomainExt } from '../../consts/phrases';
import { primary } from '../../consts/colors';

const styles = {
  container: {
    position: 'relative',
    zIndex: 100,
    width: '100vw',
    height: `${appBarHeight}px`,
    background: '#fffafc',
    boxShadow: 'rgba(0, 0, 0, 0.4) 0px 0px 5px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  span: {
    margin: '0 2rem',
    fontSize: '1.125rem',
    fontWeight: '400',
    color: primary,
    padding: '1rem 0',
  },
};

export function DummyAppBar(props) {
  return (
    <div style={styles.container}>
      <span style={styles.span}>
        {appName1}
        <span style={{ fontWeight: '200' }}>{appName2WithDomainExt}</span>
      </span>
    </div>
  );
}
