import React from 'react';
import { videoBoxShadow, appBarHeight } from '../../consts/styles';
import { HorizontalLoader } from './HorizontalLoader';

var firstVideo;
var secondVideo;

const windowWidth = window.innerWidth;
const videoWidthRatio = 0.8;
const x = windowWidth * videoWidthRatio * 1.3;

const styles = {
  container: {
    background: 'white',
    position: 'absolute',
    zIndex: 200,
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
  },
  label: {
    position: 'absolute',
    background: 'rgba(0,0,0,0.1)',
    color: 'white',
    padding: '0.3rem',
  },
  loaderContainer: {
    position: 'absolute',
    background: 'grey',
    width: 'inherit',
    height: 'inherit',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    zIndex: -1,
    borderRadius: 'inherit',
  },
  videoBackground: {
    zIndex: 10,
    flex: 1,
    width: windowWidth,
    background: '#dec8ce',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    paddingTop: `${appBarHeight + 58}px`,
  },
  videoContainer: {
    width: `${videoWidthRatio * 100}%`,
    alignSelf: 'center',
    height: x,
    boxShadow: videoBoxShadow,
    borderRadius: '5px',
  },
  video: {
    width: '100%',
    borderRadius: 'inherit',
  },
};

// export default class VideoLoop extends React.Component {
class VideoLoop extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstVideoDisplay: 'inline',
      secondVideoDisplay: 'none',
    };
  }

  componentDidMount() {
    firstVideo = document.getElementById('firstVideo');
    firstVideo.addEventListener(
      'error',
      function(ev) {
        console.error('MediaRecording.recordedMedia.error()');
        console.log(
          'Your browser can not play\n\n' +
            firstVideo.src +
            '\n\n media clip. event: ' +
            JSON.stringify(ev)
        );
      },
      true
    );
    firstVideo.addEventListener(
      'ended',
      this.firstVideoEnded.bind(this),
      false
    );

    secondVideo = document.getElementById('secondVideo');
    secondVideo.addEventListener(
      'error',
      function(ev) {
        console.error('MediaRecording.recordedMedia.error()');
        console.log(
          'Your browser can not play\n\n' +
            secondVideo.src +
            '\n\n media clip. event: ' +
            JSON.stringify(ev)
        );
      },
      true
    );
    secondVideo.addEventListener(
      'ended',
      this.secondVideoEnded.bind(this),
      false
    );
    firstVideo.play();
  }

  componentWillUnmount() {
    firstVideo.pause();
    secondVideo.pause();
  }

  firstVideoEnded() {
    if (this.props.answerSrc) {
      secondVideo.play();
      this.setState({
        firstVideoDisplay: 'none',
        secondVideoDisplay: 'inline',
      });
    } else {
      firstVideo.play();
    }
  }

  secondVideoEnded() {
    firstVideo.play();
    this.setState({
      firstVideoDisplay: 'inline',
      secondVideoDisplay: 'none',
    });
  }

  render() {
    return (
      <div className="video-loop" style={styles.container}>
        <div style={styles.videoBackground}>
          <div
            style={styles.videoContainer}
            onClick={() => window.history.back()}
          >
            <div style={styles.loaderContainer}>
              <HorizontalLoader theme="white" />
            </div>
            <div
              style={{
                display: this.state.firstVideoDisplay,
                borderRadius: 'inherit',
              }}
            >
              <span style={styles.label}>
                {this.props.questionerDisplayName || null}
              </span>
              <video
                disableremoteplayback="true"
                id="firstVideo"
                style={styles.video}
                playsInline
                src={this.props.questionSrc}
                type="video/webm"
              />
            </div>
            <div
              style={{
                display: this.state.secondVideoDisplay,
                borderRadius: 'inherit',
              }}
            >
              <span style={styles.label}>
                {this.props.answererDisplayName || null}
              </span>
              <video
                disableremoteplayback="true"
                id="secondVideo"
                style={styles.video}
                playsInline
                src={this.props.answerSrc}
                type="video/webm"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default VideoLoop;
