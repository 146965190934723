import React from 'react';
import { connect } from 'react-redux';
import OpenQuestion from './OpenQuestion';
import { primaryLight, primaryMidDark } from '../consts/colors';

import { reduxUpdate } from '../actions/Actions';

import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import { appBarHeight, defaultBoxShadow, slightBoxShadow } from '../consts/styles';
import {
  createConvoUid,
  updateURLFragmentString,
} from '../utility/functions/misc';
import { updateLastContact } from '../utility/functions/cloudFirestoreFunctions';
import { HorizontalLoader } from './common/HorizontalLoader';

const matchSpace = '0.5rem'
const styles = {
  date: {
    fontSize: '14px',
    fontWeight: '300',
    marginRight: '3rem',
  },
  loaderContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    height: '70%',
  },
  outerContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  innerContainer: {
    background: 'white',
    padding: '10px 7px',
    overflowY: 'scroll',
    overflowX: 'hidden',
  },
  videoButtonContainer: {
    alignItems: 'center',
  },
  inputOuterContainer: {
    bottom: 0,
    position: 'fixed',
    padding: '7px 0',
    background: 'rgba(0, 0, 0, .1)',
    boxShadow: '2px 3px 9px rgba(0, 0, 0, .1)',
    display: 'flex',
    width: '100%',
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-end',
    border: '1px solid rgb(189, 189, 189)',
    borderRadius: '2rem',
    margin: '0 7px',
    padding: `${matchSpace} 0`,
    boxShadow: '2px 3px 9px rgba(0, 0, 0, .1)',
    background: 'white',
    width: '100%',
  },
  messageContainerStyle: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '0.5rem',
  },
  videoButton: {
    width: '5rem',
    height: '5rem',
    backgroundColor: '#73beff',
    borderRadius: '10px',
    border: '1px solid white',
    boxShadow: '-2px 3px 18px rgba(0, 0, 0, .2)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  playIcon: {
    color: 'white',
    fontSize: '3rem',
    width: '3rem', // Should equal icon's fontSize
  },
  formControl: {
    flex: 1,
  },
  sendButton: {
    color: primaryMidDark,
    fontSize: '2rem',
    padding: '0 0.5rem',
  },
  scrollingSpacer: {
    width: '100%',
    height: '65px',
  },
};

const dateOptions = { weekday: 'long', month: '2-digit', day: '2-digit' };
const timeOptions = { hour: '2-digit', minute: '2-digit' };

let db;
let convoUid;
let unsubscribe;
let unseenQuestion;

class Conversation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inputText: '',
      messages: [],
      fetchingMessages: true,
    };

    db = window.firestore;
    convoUid = createConvoUid(props.uid, props.friendUid);
  }

  componentDidMount() {
    this.scrollToBottom();
    const { uid } = this.props;

    unsubscribe = db
      .collection('convos')
      .doc(convoUid)
      .collection('messages')
      // This will pull in the most recent messages, but we will have to reverse the order before rendering:
      .orderBy('date', 'desc')
      .limit(18) // TODO allow user to load more messages
      .onSnapshot((querySnapshot) => {
        unseenQuestion = false;
        let messages = [];
        querySnapshot.forEach(function(doc) {
          const message = doc.data();
          if (message.type === 'question' && message.author !== uid) {
            unseenQuestion = true;
          }
          messages.push({ ...message, documentId: doc.id });
        });
        messages.reverse();
        this.setState({
          messages,
          fetchingMessages: false,
        });
      })
      .bind(this);
  }

  componentDidUpdate() {
    this.scrollToBottom();
  }

  componentWillUnmount() {
    this.props.onConversationUnmount(unseenQuestion);
    unsubscribe();
  }

  scrollToBottom() {
    // this.messagesEnd.scrollIntoView({
    //   behavior: 'smooth',
    // })
    // The above code wouldn't smooth scroll, so we're doing this for now:
    if (this.innerContainer) {
      this.innerContainer.scroll({ top: 99999, behavior: 'smooth' });
    }
    // window.scrollBy(0, 10);
  }

  inputChange = (event) => {
    const {value} = event.target;
    if (value?.length > 100) {
      const snackbar = {
        open: true,
        message: `Take it easy with all the words`,
      };
      return this.props.reduxUpdate({
        prop: 'snackbar',
        value: snackbar,
      });
    } else {
      this.setState({
        inputText: value,
      });
    }
  };

  handleVideoButtonClick = (
    questionSrc,
    answerSrc = null,
    authoredByClient
  ) => {
    const srcUrls = answerSrc ? [questionSrc, answerSrc] : [questionSrc];
    const questionerDisplayName = authoredByClient
      ? this.props.displayName
      : this.props.friendDisplayName;
    const answererDisplayName = authoredByClient
      ? this.props.friendDisplayName
      : this.props.displayName;
    const value = {
      srcUrls,
      questionerDisplayName,
      answererDisplayName,
    };
    this.props.reduxUpdate({
      prop: 'videoLoop',
      value,
    });
    updateURLFragmentString('convo-video-loop');
  };

  handleQuestionClick = (questionSrc, documentId) => {
    this.setState({
      openQuestion: {
        questionSrc,
        documentId,
      },
    });
    updateURLFragmentString('convo-oq');
  };

  sendMessage() {
    const date = new Date();
    const message = {
      author: this.props.uid,
      authorDisplayName: this.props.displayName,
      type: 'text',
      content: this.state.inputText,
      date,
    };

    // add message to collection
    db.collection('convos')
      .doc(convoUid)
      .collection('messages')
      .add(message)
      .then((docRef) => {
        console.log('Document written with ID: ', docRef.id);
        // TODO Visually confirm message was sent

        // Update the last contact date for conversation
        updateLastContact(
          convoUid,
          date,
          'text',
          this.props.uid,
          this.props.notificationsToken,
          this.props.friendUid
        );
      })
      .catch(function(error) {
        console.error('Error adding document: ', error);
      });
    // (COULDN'T DO A BATCH WRITE WITH .add())

    this.setState({
      inputText: '',
    });
  }

  render() {
    const outerContainerHeight = this.props.windowHeight - appBarHeight;
    return (
      <div
        style={{ ...styles.outerContainer, height: outerContainerHeight }}
        className="conversation"
      >
        {this.state.openQuestion &&
          this.props.appearanceType === 'convo-oq' && (
            <OpenQuestion
              questionSrc={this.state.openQuestion.questionSrc}
              documentId={this.state.openQuestion.documentId}
              authorUid={this.props.friendUid || null}
              authorDisplayName={this.props.friendDisplayName || null}
              pathQuestion={false}
            />
          )}
        {this.state.fetchingMessages ? (
          <div style={styles.loaderContainer}>
            <HorizontalLoader />
          </div>
        ) : (
          <div
            style={styles.innerContainer}
            ref={(el) => {
              this.innerContainer = el;
            }}
          >
            {this.state.messages.map((message) => {
              const authoredByClient =
                message.author === this.props.uid ? true : false;
              if (message.type === 'text') {
                const alignItems = authoredByClient ? 'flex-end' : 'flex-start';
                const backgroundColor = authoredByClient
                  ? '#f9eaf4'
                  : '#e2f4fb';
                const textStyle = {
                  maxWidth: '70%',
                  backgroundColor,
                  color: '#4c4c4c',
                  padding: '0.4rem 0.7rem',
                  borderRadius: '1rem',
                  boxShadow: '-2px 3px 18px rgba(0, 0, 0, .2)',
                  border: `1px solid white`,
                };
                return (
                  <div
                    key={message.date}
                    style={{ ...styles.messageContainerStyle, alignItems }}
                  >
                    <span style={textStyle}>{message.content}</span>
                  </div>
                );
              } else if (message.type === 'question' && !authoredByClient) {
                // Client needs to answer a question
                return (
                  <div
                    key={message.date}
                    style={{
                      ...styles.messageContainerStyle,
                      alignItems: 'flex-start',
                      padding: '1rem 0 1rem 0.5rem',
                    }}
                  >
                    <button
                      onClick={() =>
                        this.handleQuestionClick(
                          message.questionSrc,
                          message.documentId
                        )
                      }
                      style={{
                        ...styles.videoButton,
                        background: primaryLight,
                        boxShadow: '#ff096d 0px 0px 23px',
                      }}
                    >
                      <i className="material-icons" style={styles.playIcon}>
                        play_circle_outlined
                      </i>
                    </button>
                  </div>
                );
              } else if (message.type === 'question') {
                return (
                  <div
                    key={message.date}
                    style={{
                      ...styles.messageContainerStyle,
                      alignItems: 'flex-end',
                    }}
                  >
                    <button
                      onClick={() =>
                        this.handleVideoButtonClick(
                          message.questionSrc,
                          null,
                          true
                        )
                      }
                      style={styles.videoButton}
                    >
                      <i className="material-icons" style={styles.playIcon}>
                        play_circle_outlined
                      </i>
                    </button>
                  </div>
                );
              } else if (message.type === 'complete') {
                const dateObject = message.date.toDate();
                const dateString = dateObject.toLocaleString(
                  'en-US',
                  dateOptions
                );
                const timeString = dateObject.toLocaleString(
                  'en-US',
                  timeOptions
                );
                return (
                  <div
                    key={message.date}
                    style={{
                      ...styles.messageContainerStyle,
                      alignItems: 'center',
                    }}
                  >
                    <p style={styles.date}>
                      {dateString} {timeString}
                    </p>
                    <button
                      onClick={() =>
                        this.handleVideoButtonClick(
                          message.questionSrc,
                          message.answerSrc,
                          authoredByClient
                        )
                      }
                      style={{
                        ...styles.videoButton,
                        boxShadow: '#449afc 0px 0px 9px',
                      }}
                    >
                      <i className="material-icons" style={styles.playIcon}>
                        play_circle_outlined
                      </i>
                    </button>
                  </div>
                );
              } else {
                return null;
              }
            })}
            <div style={styles.scrollingSpacer} />
          </div>
        )}
        <div style={styles.inputOuterContainer}>
          <div
            style={styles.inputContainer}
            onClick={this.scrollToBottom.bind(this)}
          >
            <button
              onClick={() => {
                const snackbar = {
                  open: true,
                  message: `We're still workin on this guy`,
                };
                return this.props.reduxUpdate({
                  prop: 'snackbar',
                  value: snackbar,
                });
              }}
            >
              <i className="material-icons" style={{
                borderRadius: '50%',
                background: primaryMidDark,
                width: '2.4rem',
                height: '2.4rem',
                margin: `0 ${matchSpace}`,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                boxShadow: slightBoxShadow,
                border: '1px solid white',
              }}>
                videocam
              </i>
            </button>
            <FormControl style={styles.formControl}>
              <Input
                autoComplete="off"
                type="text"
                id="message-field"
                onChange={this.inputChange}
                value={this.state.inputText}
              />
            </FormControl>
            <button onClick={() => this.sendMessage()} style={{height: '100%'}}>
              <i className="material-icons" style={styles.sendButton}>
                send
              </i>
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { displayName, notificationsToken, uid } = state.global.user;
  const { appearanceType } = state.global.appearance;
  const { windowHeight } = state.global;
  return { appearanceType, displayName, notificationsToken, uid, windowHeight };
};

export default connect(mapStateToProps, { reduxUpdate })(Conversation);
