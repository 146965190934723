import { createConvoUid } from './misc';

export const addFriendToDB = (thisUser, newFriend) => {
  return new Promise((resolve, reject) => {
    const { firestore } = window;
    // Get a new write batch
    var batch = firestore.batch();

    // add friendUid to user's friend list
    // Need to nest friends doc in a new friends collection because
    // Couldn't find a way to update based on uid as the key directly in the user data:
    const friendsOfThisUserRef = firestore
      .collection('users')
      .doc(thisUser.uid)
      .collection('friends')
      .doc('friends');
    batch.update(friendsOfThisUserRef, { [newFriend.uid]: newFriend });

    // Add this user to newFriend's friend list:
    const friendsOfNewFriendRef = firestore
      .collection('users')
      .doc(newFriend.uid)
      .collection('friends')
      .doc('friends');
    batch.update(friendsOfNewFriendRef, { [thisUser.uid]: thisUser });

    // make convo uid from two ids
    // add uids & last updated to convo document:
    const convoUid = createConvoUid(thisUser.uid, newFriend.uid);
    const convoRef = firestore.collection('convos').doc(convoUid);

    batch.set(convoRef, {
      convoUsers: [thisUser.uid, newFriend.uid],
      lastContact: new Date(),
    });

    // COMMIT THEM ALL
    batch
      .commit()
      .then(() => {
        resolve(newFriend);
      })
      .catch(function(error) {
        console.error('Error adding document: ', error);
        reject(error);
      });
  });
};

export const anonymousUserConnect = (thisUserAnonymousUid, newFriendUid) => {
  return new Promise((resolve, reject) => {
    const { firestore } = window;
    // make convo uid from two ids
    // add uids & last updated to convo document:
    const convoUid = createConvoUid(thisUserAnonymousUid, newFriendUid);
    const convoRef = firestore.collection('convos').doc(convoUid);

    convoRef
      .set({
        convoUsers: [thisUserAnonymousUid, newFriendUid],
        lastContact: new Date(),
      })
      .then(() => {
        console.log('Successfully connected as anonymous user in convos');
        resolve(newFriendUid);
      })
      .catch(function(error) {
        console.error('Error adding document: ', error);
        reject(error);
      });
  });
};

export const fetchFriends = () => {
  return new Promise((resolve, reject) => {
    // Need to nest friends object in a new collection because
    // couldn't find a way to update based on uid as the key directly in the user data:
    window.firestoreUserRef
      .collection('friends')
      .doc('friends')
      .onSnapshot((doc) => {
        resolve(doc.data());
      })
  });
};

export const updateLastContact = (
  convoUid,
  date,
  newMessageType,
  uid,
  notificationsToken,
  friendUid = null
) => {
  let updateObject = {
    [uid]: {
      // lastContact: date, // We use this to check if the client is up to date with all messages, probably not anymore
      notificationsToken, // Good to do if anything changes, not the source of truth
    },
  };

  if (newMessageType === 'upToDate' || newMessageType === 'unansweredQ') {
    updateObject = {
      ...updateObject,
      [uid]: {
        textUnseen: false,
        completeUnseen: false,
        questionUnseen: newMessageType === 'unansweredQ',
      },
    };
  } else if (
    newMessageType === 'text' ||
    newMessageType === 'complete' ||
    newMessageType === 'question'
  ) {
    // newMessageType is text or complete or question here
    const newMessageDescriptionForDB = newMessageType + 'Unseen';
    updateObject = {
      ...updateObject,
      lastAuthor: uid, // probably not needed anymore
      lastContact: date, // probably not needed anymore
      newMessageType, // probably not needed anymore
      [friendUid]: {
        [newMessageDescriptionForDB]: true,
      },
    };
  }
  const convoRef = window.firestore.collection('convos').doc(convoUid);
  convoRef.set(updateObject, { merge: true }); // merge: true - allows existing data to persist
};

// Update user object:
export const updateUser = (uid, key, value) => {
  const userRef = window.firestore.collection('users').doc(uid);
  return userRef
    .update({
      [key]: value,
    })
    .then(function() {
      console.log('User successfully updated!');
    })
    .catch(function(error) {
      // The document probably doesn't exist.
      console.error('Error updating user: ', error);
    });
};

// Write a share ref:
export const writeShareRef = (authorUid, authorDisplayName, storageUrl) => {
  return new Promise((resolve, reject) => {
    const shareObject = {
      authorUid,
      authorDisplayName,
      storageUrl,
      usersThatAnswered: [],
    };

    window.firestore
      .collection('share')
      .add(shareObject)
      .then(function(docRef) {
        console.log('Document written with this ID: ', docRef.id);
        resolve(docRef.id);
      })
      .catch(function(error) {
        console.error('Error adding document: ', error);
        reject(error);
      });
  });
};
