import React from 'react';
import { primaryLight } from '../../consts/colors';

export function TimerStrip(props) {
  const timerStripBackground =
    props.status === 'RECORDING' ? primaryLight : 'white';
  const timerStripClassName = `timerStrip ${
    props.status === 'RECORDING' ? 'recording' : ''
  }`;
  return (
    <div style={{ background: 'white', height: '3px' }}>
      <div
        className={timerStripClassName}
        id={props.timerStripId}
        style={{
          background: timerStripBackground,
          height: '100%',
          margin: '0 auto',
        }}
      />
    </div>
  );
}
