import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';

import VideoLoop from './common/VideoLoop';
import { reduxUpdate } from '../actions/Actions';

import {
  charcoal,
  gray,
  primary,
  primarySuperDark,
  backgroundBright,
} from '../consts/colors';
import { appBarHeight, slightBoxShadow } from '../consts/styles';
import {
  fullscreenPortraitMode,
  updateURLFragmentString,
} from '../utility/functions/misc';
import { ProfilePhoto } from './common/ProfilePhoto';
import { minConvosToShowSearch } from '../consts/config';

const styles = {
  appBarContainer: {
    position: 'fixed',
    zIndex: 4000,
    width: '100vw',
    height: `${appBarHeight}px`,
    background: backgroundBright,
    boxShadow: '0 0 5px rgba(0, 0, 0, .4)',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  appBarLeftContainer: {
    marginLeft: '12px',
    display: 'flex',
  },
  appBarRightContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginRight: '1rem',
  },
  drawerContainer: {
    width: '65vw',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: backgroundBright,
  },
  fullscreenButton: {
    marginLeft: '10px',
    color: primarySuperDark,
    fontSize: '2rem',
  },
  headerText: {
    color: primary,
    fontSize: '18px',
    alignSelf: 'center',
    fontWeight: '400',
  },
  input: {
    width: '100%',
  },
  leftButton: {
    marginRight: '0.5rem',
    color: charcoal,
    border: '1px solid #efefef',
    borderRadius: '50%',
    width: '2rem',
    height: '2rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: slightBoxShadow,
    background: '#ffffff',
  },
  legalLink: {
    color: charcoal, paddingBottom: '0.5rem'
  },
  menuButton: {
    marginRight: '0.5rem',
    color: charcoal,
  },
  profilePhoto: {
    width: 110,
    height: 110,
    borderRadius: '50%',
    marginBottom: '1rem',
    boxShadow: 'rgba(0, 0, 0, 0.6) 0px 0px 9px',
  },
  searchBox: {
    display: 'flex',
    border: `${gray} 1px solid`,
    borderRadius: '1rem',
    padding: '0 0.6rem 0 1rem',
    background: 'white',
    width: 'fit-content',
  },
  searchIconContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  signOutButton: {
    color: charcoal,
    border: `1px solid ${charcoal}`,
    borderRadius: '5px',
    padding: '5px 10px',
    margin: '1.5rem 0 3rem',
  },
  upperDrawer: {
    color: charcoal,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '2rem 1rem 0',
  },
  userIcon: {
    fontSize: '3rem',
    color: primary,
  },
};

class MyAppBar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      drawerOpen: false,
    };

    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.searchInputRef = React.createRef();
  }

  componentDidUpdate(prevProps, prevState) {
    // Target when user hits the browser's back button / cancel button, then clear and blur the search input // update TODO NEEDED?
    if (
      prevProps.appearance.appearanceType === 'search' &&
      this.props.appearance.appearanceType !== 'search'
    ) {
      this.clearSearchInput();
      this.searchInputRef &&
        this.searchInputRef.current &&
        this.searchInputRef.current.blur();
    }

    if (
      (prevProps.appearance.appearanceType === 'convo-video-loop' &&
        this.props.appearance.appearanceType === 'convo') ||
      (prevProps.appearance.appearanceType === 'unauthed-video-loop' &&
        this.props.appearance.appearanceType === 'menu')
    ) {
      const value = {
        srcUrls: [],
        questionerDisplayName: null,
        answererDisplayName: null,
      };
      this.props.reduxUpdate({
        prop: 'videoLoop',
        value,
      });
    }
  }

  toggleDrawer = () => {
    this.setState({
      ...this.state,
      drawerOpen: !this.state.drawerOpen,
    });
  };

  handleSearchClick = () => {
    updateURLFragmentString('search');
    this.searchInputRef.current.focus();
  };

  handleSearchChange(event) {
    this.props.reduxUpdate({
      prop: 'searchInputValue',
      value: event.target.value,
    });
  }

  clearSearchInput = () => {
    this.props.reduxUpdate({
      prop: 'searchInputValue',
      value: '',
    });
  };

  toggleFullScreen() {
    var doc = window.document;
    var docEl = doc.documentElement;

    var requestFullScreen =
      docEl.requestFullscreen ||
      docEl.mozRequestFullScreen ||
      docEl.webkitRequestFullScreen ||
      docEl.msRequestFullscreen;
    var cancelFullScreen =
      doc.exitFullscreen ||
      doc.mozCancelFullScreen ||
      doc.webkitExitFullscreen ||
      doc.msExitFullscreen;

    if (!this.props.fullscreenPortraitMode) {
      requestFullScreen.call(docEl);
      window.screen.orientation
        .lock('portrait-primary')
        .catch((e) =>
          console.log('Unable to lock screen orientation.\nError:', e)
        );
    } else {
      cancelFullScreen.call(doc);
    }
  }

  render() {
    const { appearanceType, hideAppBar, swipeIndex } = this.props.appearance;
    if (appearanceType === 'convo-oq' && hideAppBar) {
      return null;
    }
    const messageBoxShown =
      (appearanceType === 'menu' || appearanceType === 'search') &&
      swipeIndex === 0;
    let leftButton;
    if (appearanceType === 'menu') {
      leftButton = (
        <button
          onClick={() => {
            this.toggleDrawer();
          }}
          style={styles.menuButton}
        >
          <i
            className="material-icons"
            style={{ fontSize: '1.5rem', color: primarySuperDark }}
          >
            &#xe5d2;{/* menu */}
          </i>
        </button>
      );
    } else {
      leftButton = (
        <button
          onClick={() => {
            window.history.back();
          }}
          style={styles.leftButton}
        >
          <i
            className="material-icons"
            style={{ fontSize: '1.5rem', color: primarySuperDark }}
          >
            clear
          </i>
        </button>
      );
    }
    return (
      <div>
        <div style={styles.appBarContainer}>
          <div style={styles.appBarLeftContainer}>{leftButton}</div>
          <div>
            {// If on MessageBox, show searchbar, otherwise show appName:
            messageBoxShown &&
            this.props.appearance.convosLength > minConvosToShowSearch ? (
              <div
                style={styles.searchBox}
                onClick={() => this.handleSearchClick()}
                className={'searchBox'}
              >
                <input
                  type="text"
                  style={styles.input}
                  ref={this.searchInputRef}
                  value={this.props.searchInputValue}
                  onChange={this.handleSearchChange}
                />
                <div style={styles.searchIconContainer}>
                  <i
                    className="material-icons"
                    style={{ fontSize: '2rem', color: charcoal }}
                  >
                    search
                  </i>
                </div>
              </div>
            ) : (
              <span style={styles.headerText}>
                {this.props.appearance.appBarText1}
                <span style={{ fontWeight: '200' }}>
                  {this.props.appearance.appBarText2 || ''}
                </span>
              </span>
            )}
          </div>
          <div
            style={styles.appBarRightContainer}
            className={'appBarRightContainer'}
          >
            {this.props.fullscreenPortraitMode ? null : (
              <div className={'fullscreenButtonContainer'}>
                <button onClick={fullscreenPortraitMode}>
                  <i className="material-icons" style={styles.fullscreenButton}>
                    &#xe5d0;
                  </i>
                  {/* fullscreen */}
                </button>
              </div>
            )}
          </div>
        </div>
        {this.props.videoLoop.srcUrls.length > 0 && (
          <VideoLoop
            questionSrc={this.props.videoLoop.srcUrls[0]}
            questionerDisplayName={this.props.videoLoop.questionerDisplayName}
            answerSrc={this.props.videoLoop.srcUrls[1] || null}
            answererDisplayName={this.props.videoLoop.answererDisplayName}
            windowHeight={this.props.windowHeight}
          />
        )}
        <Drawer
          open={this.state.drawerOpen}
          onClose={() => {
            this.toggleDrawer();
          }}
          style={{ zIndex: 6000 }}
        >
          <div
            style={{
              ...styles.drawerContainer,
              height: this.props.windowHeight || '100%',
            }}
          >
            <div style={styles.upperDrawer}>
              <ProfilePhoto
                photoURL={this.props.user.photoURL || null}
                photoWidth="7rem"
              />
              <span style={{ marginTop: '0.7rem' }}>
                {this.props.user.displayName}
              </span>
              <span>{this.props.user.username}</span>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <a target="_blank" rel="noopener noreferrer" style={{textDecoration: 'none', color: charcoal}} href="mailto:support@jellyjam.io">Contact</a>
              <hr style={{width: '100%', margin: '1rem 0'}} />
              <a target="_blank" rel="noopener noreferrer" style={styles.legalLink} href="https://www.websitepolicies.com/policies/view/xS9FNWaa">Terms and Conditions</a>
              <a target="_blank" rel="noopener noreferrer" style={styles.legalLink} href="https://www.websitepolicies.com/policies/view/IlUuhX9y">Privacy Policy</a>
              <a target="_blank" rel="noopener noreferrer" style={styles.legalLink} href="https://www.websitepolicies.com/policies/view/LC8bAwR7">Disclaimer</a>
              <hr style={{width: '100%'}} />
              <button
                onClick={() => this.props.signOut()}
                style={styles.signOutButton}
              >
                Sign Out
              </button>
            </div>
          </div>
        </Drawer>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    appearance,
    pointerEvents,
    fullscreenPortraitMode,
    searchInputValue,
    videoLoop,
    user,
    windowHeight,
  } = state.global;
  return {
    appearance,
    pointerEvents,
    fullscreenPortraitMode,
    searchInputValue,
    videoLoop,
    user,
    windowHeight,
  };
};

export default connect(mapStateToProps, { reduxUpdate })(
  withStyles(styles)(MyAppBar)
);
