import React from 'react';
import { connect } from 'react-redux';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

// From https://github.com/oliviertassinari/react-swipeable-views
import SwipeableViews from 'react-swipeable-views';
import MessageBox from './components/MessageBox.js';
import Camera from './components/Camera';
import MyAppBar from './components/MyAppBar';
import { reduxUpdate } from './actions/Actions';

import { backgroundBright, blue, primary } from './consts/colors';
import { appBarHeight, tabsHeight } from './consts/styles';
import { updateURLFragmentIndex } from './utility/functions/misc.js';

let destinationTab = null;

const styles = {
  headline: {
    fontSize: 24,
    paddingTop: 16,
    marginBottom: 12,
  },
  slide: {
    WebkitOverflowScrolling: 'touch', // iOS momentum scrolling
  },
  cameraContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100%',
  },
  messageBadge: {
    backgroundColor: '#317aff',
    display: 'flex',
    borderRadius: '50%',
    width: '1rem',
    color: 'white',
    justifyContent: 'center',
    fontSize: '12px',
    height: '1rem',
    alignItems: 'center',
    marginTop: '-0.3rem',
  },
  tabsContainer: {
    boxShadow: '0px 0px 5px rgba(0, 0, 0, .3)',
    position: 'fixed',
    bottom: 0,
    width: '100%',
    height: tabsHeight,
    background: backgroundBright,
  },
};

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pointerEvents: 'auto',
    };
  }

  componentDidMount() {

    // TODO Decide if you want this in prod
    // Prevent users from leaving the site, haven't been able to edit this at all!:
    // window.addEventListener('beforeunload', (event) => {
    //   // Cancel the event as stated by the standard.
    //   event.preventDefault();
    //   // Chrome requires returnValue to be set.
    //   event.returnValue = '';
    // });

    window.onpopstate = (e) => {
      if (destinationTab !== null) {
        const tab = destinationTab;
        destinationTab = null;
        updateURLFragmentIndex(tab);
      }
    };

    // var mediaSource = new MediaSource();
    // recordedVideo = document.querySelector('video#recorded');
    // const x = `video#${this.props.id}`;
    // video = document.querySelector(x);
    // video = document.querySelector('video#messageBox');
    // streamBackground = document.querySelector('video#streamBackground');
    // recordedVideo.addEventListener('error', function(ev) {
    //   console.error('MediaRecording.recordedMedia.error()');
    //   alert('Your browser can not play\n\n' + recordedVideo.src
    //     + '\n\n media clip. event: ' + JSON.stringify(ev));
    // }, true);
  }

  handleTabChange = (event, value) => {
    // Most unlikely, but target when the user is "searching" and clicks the tab on the right (clicking theaters icon)
    if (
      value === 2 &&
      this.props.swipeIndex === 0 &&
      this.props.appearanceType === 'search'
    ) {
      destinationTab = value; // after back() resolves, in the onPopState
      return window.history.go(-2);
      // Check if we're going from the right tab straight to the left, or vice versa (clicking not swiping)
    } else if (
      (value === 2 && this.props.swipeIndex === 0) ||
      (value === 0 && this.props.swipeIndex === 2)
    ) {
      destinationTab = value; // after back() resolves, in the onPopState
      return window.history.back();
    } else if (value === 1) {
      // target when the user is "searching" and swiped to the right, we need to go back twice
      if (this.props.appearanceType === 'search') {
        return window.history.go(-2);
      }
      return window.history.back();
    } else {
      updateURLFragmentIndex(value);
    }
  };

  handleChangeIndex = (value) => {
    // If user is on an outer swipeable view, send them back in history to camera.js
    if (value === 1) {
      // target when the user is "searching" and swiped to the right, we need to go back twice
      if (this.props.appearanceType === 'search') {
        return window.history.go(-2);
      }
      return window.history.back();
    }
    updateURLFragmentIndex(value);
  };

  renderCamera() {
    if (window.stream) {
      return (
        <div style={styles.cameraContainer}>
          <Camera id="prompt" />
        </div>
      );
    } else {
      return <p>please wait</p>;
    }
  }

  render() {
    const tabsContainerDisplay = this.props.showTabs ? 'block' : 'none';
    // Due to scrolling issues with the keyboard in Conversation.js, we had to hide the camera:
    const cameraDisplay =
      this.props.appearanceType === 'convo' ? 'none' : 'block';
    const { mostImportantUnseen } = this.props;
    const messageBadgeColor =
      mostImportantUnseen === 'question' ? primary : blue;
    const swipeableFragments = ['menu', 'search'];
    const disabled =
      !swipeableFragments.includes(this.props.appearanceType) ||
      this.props.disableSwipe;
    return (
      <div>
        <MyAppBar
          style={{ pointerEvents: this.props.pointerEvents }}
          signOut={this.props.signOut}
        />
        <SwipeableViews
          index={this.props.swipeIndex}
          onChangeIndex={this.handleChangeIndex}
          style={{
            pointerEvents: this.props.pointerEvents,
            height: this.props.windowHeight - appBarHeight,
            paddingTop: appBarHeight,
          }}
          disabled={disabled}
        >
          <div
            style={{
              ...styles.slide,
              height: this.props.windowHeight - appBarHeight,
            }}
          >
            {/* We don't want to load MessageBox unless the friend data is available in redux: */}
            {this.props.friendsObjectRetrieved ? <MessageBox /> : <div /> // TODO Handle when fetchFriends() fails, (we do show a 'you have no friends' message in message box)
            }
          </div>
          <div
            style={{
              ...styles.slide,
              height: this.props.windowHeight - appBarHeight - tabsHeight,
              display: cameraDisplay, // toggle camera display for scolling fix in Conversation.js
            }}
          >
            {this.renderCamera()}
          </div>
          <div
            style={{
              ...styles.slide,
              height: this.props.windowHeight - appBarHeight,
              display: 'flex',
              flexDirection: 'column',
              background: backgroundBright,
            }}
          >
            <div>
              <div id="breathing" style={{ background: '#ffbcbc' }} />
            </div>
          </div>
        </SwipeableViews>
        <div
          style={{
            ...styles.tabsContainer,
            display: tabsContainerDisplay,
          }}
        >
          <Tabs
            onChange={this.handleTabChange}
            value={this.props.swipeIndex}
            style={{ pointerEvents: this.props.pointerEvents }}
            fullWidth
          >
            <Tab
              icon={
                <div style={{ display: 'flex' }}>
                  <i
                    className="material-icons"
                    style={{ fontSize: '1.7rem', color: '#56042d' }}
                  >
                    &#xe0bf;{/* forum */}
                  </i>
                  {mostImportantUnseen ? (
                    <div
                      style={{
                        ...styles.messageBadge,
                        backgroundColor: messageBadgeColor,
                      }}
                    >
                      {/* <span>22</span> Potential for number of unseen messages */}
                    </div>
                  ) : null}
                </div>
              }
              value={0}
            />
            <Tab
              icon={
                <i
                  className="material-icons"
                  style={{ fontSize: '1.8rem', color: '#56042d' }}
                >
                  &#xe3b0; {/* camera_alt */}
                </i>
              }
              value={1}
              style={{
                borderLeft: '1px solid #eaeaea',
                borderRight: '1px solid #eaeaea',
              }}
            />
            <Tab
              icon={
                <i
                  className="material-icons"
                  style={{ fontSize: '1.5rem', color: '#56042d' }}
                >
                  &#xe92c; {/* accessibility_new */}
                </i>
              }
              value={2}
            />
          </Tabs>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { pointerEvents, windowHeight } = state.global;
  const {
    appearanceType,
    disableSwipe,
    mostImportantUnseen,
    showTabs,
    swipeIndex,
  } = state.global.appearance;
  const friendsObjectRetrieved = state.global.friendsObject;
  return {
    appearanceType,
    disableSwipe,
    friendsObjectRetrieved,
    mostImportantUnseen,
    pointerEvents,
    showTabs,
    swipeIndex,
    windowHeight,
  };
};

export default connect(mapStateToProps, { reduxUpdate })(Home);
