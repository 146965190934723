import React from 'react';
import { Provider } from 'react-redux';
import { createStore } from 'redux';

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { primary } from './consts/colors';

import reducers from './reducers';
import Auth from './Auth';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: primary,
      contrastText: 'white'
    },
    secondary: { main: primary }
  }
});

export default class App extends React.Component {
  render() {
    const store = createStore(
      reducers,
      {},
      // applyMiddleware(ReduxThunk),
      window.__REDUX_DEVTOOLS_EXTENSION__ &&
        window.__REDUX_DEVTOOLS_EXTENSION__()
    );
    return (
      <Provider store={store}>
        <MuiThemeProvider theme={theme}>
          <Auth />
        </MuiThemeProvider>
      </Provider>
    );
  }
}
